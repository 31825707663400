import * as THREE from "three";
import { FloatType, Vector2, WebGLRenderTarget } from "three";
import Blit from "./blit";

export default class BlurReflectionDistance {
    constructor(renderer, width, height) {
        let pr = 0.45;

        this.fb0 = new WebGLRenderTarget(width * pr, height * pr, { type: FloatType });
        this.fb1 = new WebGLRenderTarget(width * pr, height * pr, { type: FloatType });

        this.material = new THREE.ShaderMaterial({
            uniforms: {
                uInputTexture: { type: "t", value: null },
                uColorTexture: { type: "t", value: null },
                uOriginalTexture: { type: "t", value: null },
                uHorizontal: { value: false },
                uStep: { value: 1 },
                uPixelStep: { value: new Vector2(1 / (width * pr), 1 / (height * pr)) },
            },
            
            vertexShader: `
                varying vec2 vUv;

                void main() {
                    vUv = uv;
                    gl_Position = vec4(position.xy, 0.0, 1.0);    
                }
            `,

            fragmentShader: `
                uniform sampler2D uInputTexture;
                uniform sampler2D uOriginalTexture;
                uniform sampler2D uColorTexture;

                uniform vec2 uPixelStep;
                uniform bool uHorizontal;
                uniform float uStep;

                varying vec2 vUv;

                float weight[5] = float[] (0.227027, 0.1945946, 0.1216216, 0.054054, 0.016216);

                void main() {   
                    

                    // prendi il valore mediano e poi basi il blur radius su quello
                    float average = 0.0;
                    for(int i = -4; i <= +4; i++) {
                        vec2 offs = vec2(0.0); 

                        if(uHorizontal)  offs = vec2(uPixelStep.x * float(i) * (1.0 + uStep * 1.0), 0.0);
                        if(!uHorizontal) offs = vec2(0.0, uPixelStep.x * float(i) * (1.0 + uStep * 1.0));

                        // if(uHorizontal)  offs = vec2(uPixelStep.x * float(i), 0.0);
                        // if(!uHorizontal) offs = vec2(0.0, uPixelStep.x * float(i));

                        // USING ORIGINAL TEXTURE
                        // USING ORIGINAL TEXTURE
                        // USING ORIGINAL TEXTURE
                        // USING ORIGINAL TEXTURE
                        // USING ORIGINAL TEXTURE
                        float value = texture2D(uOriginalTexture, vUv + offs).x;

                        average += value;
                    }
                    average /= 9.0;


                    // float blurRadius = 0.25 + average * 15.0;
                    float blurRadius = 0.125 + average * 7.5;


                    // float accum = 0.0;
                    // for(int i = -4; i <= +4; i++) {
                    //     vec2 offs = vec2(0.0); 

                    //     if(uHorizontal)  offs = vec2(uPixelStep.x * float(i) * blurRadius, 0.0);
                    //     if(!uHorizontal) offs = vec2(0.0, uPixelStep.x * float(i) * blurRadius);

                    //     float value = texture2D(uInputTexture, vUv + offs).x;
                        
                    //     float weightedValue = 0.0;
                    //     if(i < 0) weightedValue = value * weight[abs(i)];
                    //     if(i > 0) weightedValue = value * weight[i];
                    //     if(i == 0) weightedValue = value * weight[0];

                    //     accum += weightedValue;
                    // }
                    
                    // gl_FragColor = vec4(vec3(accum), 1.0);


                    vec3 accum = vec3(0.0);
                    for(int i = -4; i <= +4; i++) {
                        vec2 offs = vec2(0.0); 

                        if(uHorizontal)  offs = vec2(uPixelStep.x * float(i) * blurRadius, 0.0);
                        if(!uHorizontal) offs = vec2(0.0, uPixelStep.x * float(i) * blurRadius);

                        vec3 value = texture2D(uInputTexture, vUv + offs).xyz;
                        
                        vec3 weightedValue = vec3(0.0);
                        if(i < 0) weightedValue = value * weight[abs(i)];
                        if(i > 0) weightedValue = value * weight[i];
                        if(i == 0) weightedValue = value * weight[0];

                        accum += weightedValue;
                    }
                    
                    gl_FragColor = vec4(accum, 1.0);
                }
            `,

            depthTest:  false,
            depthWrite: false,
        });

        this.mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(2,2), this.material);
        this.camera = new THREE.PerspectiveCamera( 45, 1 /* remember that the camera is worthless here */, 1, 1000 );
        this.renderer = renderer;

        this.scene = new THREE.Scene();
        this.scene.add(this.mesh);
        
        this.blitProgram = new Blit(renderer);
    }

    blur(reflectionDistanceTexture, colorTexture, renderTargetDest) {

        this.material.uniforms.uOriginalTexture.value = reflectionDistanceTexture;
        this.material.uniforms.uColorTexture.value = colorTexture;

        // ping pong blur
        for(let i = 0; i < 7; i++) {
            this.material.uniforms.uStep.value = 1 + i;

            // horizontal pass
            this.renderer.setRenderTarget(this.fb0);
            this.material.uniforms.uHorizontal.value = true;
            // this.material.uniforms.uHorizontal.value = i % 2 == 0;
            this.material.uniforms.uInputTexture.value = this.fb1.texture;
            // if(i === 0) this.material.uniforms.uInputTexture.value = reflectionDistanceTexture;
            if(i === 0) this.material.uniforms.uInputTexture.value = colorTexture;
            this.renderer.render(this.scene, this.camera);
        
            // vertical pass
            this.renderer.setRenderTarget(this.fb1);
            this.material.uniforms.uHorizontal.value = false;
            this.material.uniforms.uInputTexture.value = this.fb0.texture;
            this.renderer.render(this.scene, this.camera);
        }

        this.blitProgram.blit(this.fb1.texture, renderTargetDest);
    }
}