import { Color, DoubleSide, Vector3 } from "three";
import { MeshBasicMaterial } from "three";
import { Mesh } from "three";
import { Clock } from "three";
import { BufferGeometry } from "three";
import { BufferAttribute } from "three";
import { clamp, smoothstep } from "three/src/math/MathUtils";

export default class Crystals {
  constructor() {
    this.geo = new BufferGeometry();
    this.clock = new Clock();
    this.clock.start();

    let material = new MeshBasicMaterial({
      color: new Color('#FF5624').multiplyScalar(2.5),
      side: DoubleSide,
      toneMapped: false,
    });


    // crystal geometry
    let vertices = [];
    // this.createPartialCrystal(2, 0.15, new Vector3(0,1,0), 0.5, 0.95, vertices);

    this.geo.setAttribute("position", new BufferAttribute(new Float32Array(vertices), 3));

    this.mesh = new Mesh(this.geo, material);
  }

  createCrystal(length, width, transl = new Vector3(0,0,0), scale = 1, vertices) {
    let baselength = width * 1.7;
    length = length - baselength * 2;

    let base = new Vector3(0, -baselength - length * 0.5, 0);

    let v1 = new Vector3(-width, -length * 0.5, -width);
    let v2 = new Vector3(-width, -length * 0.5, +width);
    let v3 = new Vector3(+width, -length * 0.5, +width);
    let v4 = new Vector3(+width, -length * 0.5, -width);

    let v5 = v1.clone().add(new Vector3(0, length, 0));
    let v6 = v2.clone().add(new Vector3(0, length, 0));
    let v7 = v3.clone().add(new Vector3(0, length, 0));
    let v8 = v4.clone().add(new Vector3(0, length, 0));

    let top = new Vector3(0, +baselength + length * 0.5, 0);
    let structVerts = [base, v1, v2, v3, v4, v5, v6, v7, v8, top];

    // translation / scaling
    structVerts.forEach((v) => v.multiplyScalar(scale).add(transl));

    // creating vertices
    vertices.push(
      base.x, base.y, base.z,  v1.x, v1.y, v1.z,  v2.x, v2.y, v2.z,    
      base.x, base.y, base.z,  v2.x, v2.y, v2.z,  v3.x, v3.y, v3.z,    
      base.x, base.y, base.z,  v3.x, v3.y, v3.z,  v4.x, v4.y, v4.z,    
      base.x, base.y, base.z,  v4.x, v4.y, v4.z,  v1.x, v1.y, v1.z,
      
      v1.x, v1.y, v1.z,   v2.x, v2.y, v2.z,   v5.x, v5.y, v5.z,
      v2.x, v2.y, v2.z,   v5.x, v5.y, v5.z,   v6.x, v6.y, v6.z,

      v2.x, v2.y, v2.z,   v3.x, v3.y, v3.z,   v6.x, v6.y, v6.z,
      v3.x, v3.y, v3.z,   v6.x, v6.y, v6.z,   v7.x, v7.y, v7.z,
      
      v3.x, v3.y, v3.z,   v4.x, v4.y, v4.z,   v7.x, v7.y, v7.z,
      v4.x, v4.y, v4.z,   v7.x, v7.y, v7.z,   v8.x, v8.y, v8.z,
      
      v1.x, v1.y, v1.z,   v5.x, v5.y, v5.z,   v8.x, v8.y, v8.z,
      v1.x, v1.y, v1.z,   v4.x, v4.y, v4.z,   v8.x, v8.y, v8.z,
      
      top.x, top.y, top.z,  v5.x, v5.y, v5.z,  v6.x, v6.y, v6.z,    
      top.x, top.y, top.z,  v6.x, v6.y, v6.z,  v7.x, v7.y, v7.z,    
      top.x, top.y, top.z,  v7.x, v7.y, v7.z,  v8.x, v8.y, v8.z,    
      top.x, top.y, top.z,  v8.x, v8.y, v8.z,  v5.x, v5.y, v5.z,
    );
  };

  createPartialCrystal(length, width, transl = new Vector3(0,0,0), scale = 1, t = 1, vertices) {
    let lengthBasedT = t * length;

    let baselength = width * 1.7;
    length = length - baselength * 2;

    let base = new Vector3(0, -baselength - length * 0.5, 0);

    let v1 = new Vector3(-width, -length * 0.5, -width);
    let v2 = new Vector3(-width, -length * 0.5, +width);
    let v3 = new Vector3(+width, -length * 0.5, +width);
    let v4 = new Vector3(+width, -length * 0.5, -width);

    let v5 = v1.clone().add(new Vector3(0, length, 0));
    let v6 = v2.clone().add(new Vector3(0, length, 0));
    let v7 = v3.clone().add(new Vector3(0, length, 0));
    let v8 = v4.clone().add(new Vector3(0, length, 0));

    let top = new Vector3(0, +baselength + length * 0.5, 0);
    let structVerts = [base, v1, v2, v3, v4, v5, v6, v7, v8, top];

    if (lengthBasedT <= 0) {
      return;

    } else if(lengthBasedT <= baselength) {
      let nt = lengthBasedT / baselength;
      v1.add(new Vector3(0, length * 0.5 + baselength)).multiplyScalar(nt).add(new Vector3(0,-length * 0.5 - baselength,0));
      v2.add(new Vector3(0, length * 0.5 + baselength)).multiplyScalar(nt).add(new Vector3(0,-length * 0.5 - baselength,0));
      v3.add(new Vector3(0, length * 0.5 + baselength)).multiplyScalar(nt).add(new Vector3(0,-length * 0.5 - baselength,0));
      v4.add(new Vector3(0, length * 0.5 + baselength)).multiplyScalar(nt).add(new Vector3(0,-length * 0.5 - baselength,0));

      // translation / scaling
      structVerts.forEach((v) => v.multiplyScalar(scale).add(transl));

      vertices.push(
        base.x, base.y, base.z,  v1.x, v1.y, v1.z,  v2.x, v2.y, v2.z,    
        base.x, base.y, base.z,  v2.x, v2.y, v2.z,  v3.x, v3.y, v3.z,    
        base.x, base.y, base.z,  v3.x, v3.y, v3.z,  v4.x, v4.y, v4.z,    
        base.x, base.y, base.z,  v4.x, v4.y, v4.z,  v1.x, v1.y, v1.z,
      );
    } else if (lengthBasedT > baselength && lengthBasedT < (baselength + length)) {
      let nt = (lengthBasedT - baselength) / length;
      let offset = length * (1-nt);

      v5.add(new Vector3(0,-offset,0));
      v6.add(new Vector3(0,-offset,0));
      v7.add(new Vector3(0,-offset,0));
      v8.add(new Vector3(0,-offset,0));

      // translation / scaling
      structVerts.forEach((v) => v.multiplyScalar(scale).add(transl));
      
      vertices.push(
        base.x, base.y, base.z,  v1.x, v1.y, v1.z,  v2.x, v2.y, v2.z,    
        base.x, base.y, base.z,  v2.x, v2.y, v2.z,  v3.x, v3.y, v3.z,    
        base.x, base.y, base.z,  v3.x, v3.y, v3.z,  v4.x, v4.y, v4.z,    
        base.x, base.y, base.z,  v4.x, v4.y, v4.z,  v1.x, v1.y, v1.z,

        v1.x, v1.y, v1.z,   v2.x, v2.y, v2.z,   v5.x, v5.y, v5.z,
        v2.x, v2.y, v2.z,   v5.x, v5.y, v5.z,   v6.x, v6.y, v6.z,

        v2.x, v2.y, v2.z,   v3.x, v3.y, v3.z,   v6.x, v6.y, v6.z,
        v3.x, v3.y, v3.z,   v6.x, v6.y, v6.z,   v7.x, v7.y, v7.z,

        v3.x, v3.y, v3.z,   v4.x, v4.y, v4.z,   v7.x, v7.y, v7.z,
        v4.x, v4.y, v4.z,   v7.x, v7.y, v7.z,   v8.x, v8.y, v8.z,

        v1.x, v1.y, v1.z,   v5.x, v5.y, v5.z,   v8.x, v8.y, v8.z,
        v1.x, v1.y, v1.z,   v4.x, v4.y, v4.z,   v8.x, v8.y, v8.z,
      );
    } else if (lengthBasedT >= (baselength + length) && lengthBasedT < (baselength * 2 + length)) { 
      let nt = 1 - (lengthBasedT - (baselength + length)) / baselength;

      let v9  = v5.clone().add(new Vector3(0, -length * 0.5 + baselength)).multiply(new Vector3(nt, 1-nt, nt)).add(new Vector3(0, length * 0.5, 0));
      let v10 = v6.clone().add(new Vector3(0, -length * 0.5 + baselength)).multiply(new Vector3(nt, 1-nt, nt)).add(new Vector3(0, length * 0.5, 0));
      let v11 = v7.clone().add(new Vector3(0, -length * 0.5 + baselength)).multiply(new Vector3(nt, 1-nt, nt)).add(new Vector3(0, length * 0.5, 0));
      let v12 = v8.clone().add(new Vector3(0, -length * 0.5 + baselength)).multiply(new Vector3(nt, 1-nt, nt)).add(new Vector3(0, length * 0.5, 0));

      structVerts.push(v9, v10, v11, v12);

      // translation / scaling
      structVerts.forEach((v) => v.multiplyScalar(scale).add(transl));

      vertices.push(
        base.x, base.y, base.z,  v1.x, v1.y, v1.z,  v2.x, v2.y, v2.z,    
        base.x, base.y, base.z,  v2.x, v2.y, v2.z,  v3.x, v3.y, v3.z,    
        base.x, base.y, base.z,  v3.x, v3.y, v3.z,  v4.x, v4.y, v4.z,    
        base.x, base.y, base.z,  v4.x, v4.y, v4.z,  v1.x, v1.y, v1.z,

        v1.x, v1.y, v1.z,   v2.x, v2.y, v2.z,   v5.x, v5.y, v5.z,
        v2.x, v2.y, v2.z,   v5.x, v5.y, v5.z,   v6.x, v6.y, v6.z,

        v2.x, v2.y, v2.z,   v3.x, v3.y, v3.z,   v6.x, v6.y, v6.z,
        v3.x, v3.y, v3.z,   v6.x, v6.y, v6.z,   v7.x, v7.y, v7.z,

        v3.x, v3.y, v3.z,   v4.x, v4.y, v4.z,   v7.x, v7.y, v7.z,
        v4.x, v4.y, v4.z,   v7.x, v7.y, v7.z,   v8.x, v8.y, v8.z,

        v1.x, v1.y, v1.z,   v5.x, v5.y, v5.z,   v8.x, v8.y, v8.z,
        v1.x, v1.y, v1.z,   v4.x, v4.y, v4.z,   v8.x, v8.y, v8.z,


        v5.x, v5.y, v5.z,   v6.x, v6.y, v6.z,   v9.x, v9.y, v9.z,
        v6.x, v6.y, v6.z,   v9.x, v9.y, v9.z,   v10.x, v10.y, v10.z,

        v6.x, v6.y, v6.z,   v7.x, v7.y, v7.z,     v10.x, v10.y, v10.z,
        v7.x, v7.y, v7.z,   v10.x, v10.y, v10.z,  v11.x, v11.y, v11.z,

        v7.x, v7.y, v7.z,   v8.x, v8.y, v8.z,    v11.x, v11.y, v11.z,
        v8.x, v8.y, v8.z,   v11.x, v11.y, v11.z, v12.x, v12.y, v12.z,

        v5.x, v5.y, v5.z,   v9.x, v9.y, v9.z, v12.x, v12.y, v12.z,
        v5.x, v5.y, v5.z,   v8.x, v8.y, v8.z, v12.x, v12.y, v12.z,
      );
    } else if (lengthBasedT >= baselength * 2 + length) {

      // translation / scaling
      structVerts.forEach((v) => v.multiplyScalar(scale).add(transl));

      // creating vertices
      vertices.push(
        base.x, base.y, base.z,  v1.x, v1.y, v1.z,  v2.x, v2.y, v2.z,    
        base.x, base.y, base.z,  v2.x, v2.y, v2.z,  v3.x, v3.y, v3.z,    
        base.x, base.y, base.z,  v3.x, v3.y, v3.z,  v4.x, v4.y, v4.z,    
        base.x, base.y, base.z,  v4.x, v4.y, v4.z,  v1.x, v1.y, v1.z,

        v1.x, v1.y, v1.z,   v2.x, v2.y, v2.z,   v5.x, v5.y, v5.z,
        v2.x, v2.y, v2.z,   v5.x, v5.y, v5.z,   v6.x, v6.y, v6.z,

        v2.x, v2.y, v2.z,   v3.x, v3.y, v3.z,   v6.x, v6.y, v6.z,
        v3.x, v3.y, v3.z,   v6.x, v6.y, v6.z,   v7.x, v7.y, v7.z,

        v3.x, v3.y, v3.z,   v4.x, v4.y, v4.z,   v7.x, v7.y, v7.z,
        v4.x, v4.y, v4.z,   v7.x, v7.y, v7.z,   v8.x, v8.y, v8.z,

        v1.x, v1.y, v1.z,   v5.x, v5.y, v5.z,   v8.x, v8.y, v8.z,
        v1.x, v1.y, v1.z,   v4.x, v4.y, v4.z,   v8.x, v8.y, v8.z,

        top.x, top.y, top.z,  v5.x, v5.y, v5.z,  v6.x, v6.y, v6.z,    
        top.x, top.y, top.z,  v6.x, v6.y, v6.z,  v7.x, v7.y, v7.z,    
        top.x, top.y, top.z,  v7.x, v7.y, v7.z,  v8.x, v8.y, v8.z,    
        top.x, top.y, top.z,  v8.x, v8.y, v8.z,  v5.x, v5.y, v5.z,
      );
    }
  };


  update(sphereActiveTimer) {
    let delta = this.clock.getDelta();
    let time = this.clock.getElapsedTime();


    // let center = new Vector3(0.61, -0.13, 0.2);
    let center = new Vector3(0.71, -0.13, -0.05);
    let vertices = [];

    let animTime = (time * 1.5) % 12;

    let steps = 18;
    // let steps = 35;
    let rad = 0.35;
    let length = 0.15;
    let width = 0.0075;

    for(let i = 0; i < steps; i++) {

      // let timeAngleOff = animTime * 0.1; 

      // let x = Math.sin(i / (steps) * Math.PI * 2 - 1.15 + timeAngleOff) * rad;
      // let z = Math.cos(i / (steps) * Math.PI * 2 - 1.15 + timeAngleOff) * rad;

      // let pos = center.clone().add(new Vector3(x, 0, z));

      // let sinSpeed = i * 2;

    //   if(animTime < 3) {
    //     let dist = Math.abs(i - steps * 0.5);   // [0 ... steps/2]
    //     let tOffs = -dist / (steps * 0.5) * 2;  // [0 ... -2]

    //     pos.add(new Vector3(0, Math.sin(i + animTime) * 0.05 * Math.cos(animTime), 0));

    //     // this.createPartialCrystal(length, width, pos, 1 + Math.sin(sinSpeed + Math.PI * 1.25) * 0.3, animTime + tOffs, vertices);
    //     this.createPartialCrystal(length * (1 + Math.sin(sinSpeed + Math.PI * 1.25) * 0.3), width, pos, 1, animTime + tOffs, vertices);
    //   } else if (animTime < 6) {
    //     let newT = (animTime - 3) / 1.5;

    //     let scaleOffset = (Math.sin(newT * Math.PI - Math.PI * 0.5) * 0.5 + 0.5) * 3.0 + newT * 1.5;

    //     pos.add(new Vector3(0, Math.sin(i + animTime) * 0.05 * Math.cos(animTime), 0));

    //     // this.createPartialCrystal(length, width, pos, 1 + Math.sin(sinSpeed + Math.PI * 1.25 + scaleOffset) * 0.3, 1, vertices);
    //     this.createPartialCrystal(length * (1 + Math.sin(sinSpeed + Math.PI * 1.25 + scaleOffset) * 0.3), width, pos, 1, 1, vertices);
    //   } else if (animTime < 12) {
    //     let newT = (animTime - 6) / 1.5;

    //     let scaleOffset = 6.0 + 3.0 + newT * 1.5;

    //     let show = 1;
    //     if(animTime > 11) {
    //       show = (1 - (animTime - 11)) * 3;   // [3 ... 0]

    //       let dist = Math.abs(i - steps * 0.5);   // [0 ... steps/2]
    //       let tOffs = 2 - dist / (steps * 0.5) * 2;  // [2 ... 0]

    //       show -= tOffs;

    //       if(show > 1) show = 1;
    //       if(show < 0) show = 0;
    //     }

    //     pos.add(new Vector3(0, Math.sin(i + animTime) * 0.05 * Math.cos(animTime), 0));

    //     // this.createPartialCrystal(length, width, pos, 1 + Math.sin(sinSpeed + Math.PI * 1.25 + scaleOffset) * 0.3, show, vertices);
    //     this.createPartialCrystal(length * (1 + Math.sin(sinSpeed + Math.PI * 1.25 + scaleOffset) * 0.3), width, pos, show, 1, vertices);
    //   } 

    let timeAngleOff = time * 0.1; 

    let x = Math.sin(i / (steps) * Math.PI * 2 - 1.15 + timeAngleOff) * rad;
    let z = Math.cos(i / (steps) * Math.PI * 2 - 1.15 + timeAngleOff) * rad;

    let pos = center.clone().add(new Vector3(x, 0, z));
    let sinSpeed = i * 2;

    let newT = time;
    let scaleOffset = newT * 1.5;
    let scale = smoothstep(clamp(sphereActiveTimer, 0, 1), 0, 1);

    pos.add(new Vector3(0, Math.sin(i + time) * 0.05 * Math.cos(time), 0));
    this.createPartialCrystal(length * (1 + Math.sin(sinSpeed + Math.PI * 1.25 + scaleOffset) * 0.3), width, pos, scale, 1, vertices);
  }



    // let crystalsN = 20;
    // if(animTime < 12) {
    //   let showT = animTime;
    //   let scaleT = 1;
    //   if(animTime > 11) {
    //     scaleT = Math.max(Math.min(1 - (animTime - 11), 1), 0);
    //   }

    //   if(!this.floatingCrystalsData) {
    //     this.floatingCrystalsData = [];
    //     for(let i = 0; i < crystalsN; i++) {
    //       // let angle = Math.PI * 2 * Math.random();
    //       let angle = (Math.PI * 2 * (i / crystalsN) + Math.random() * 0);
    //       let rad = Math.random() * 0.75 + 0.75;
  
    //       let pos = center.clone().add(
    //         new Vector3(
    //           Math.sin(angle) * rad, 
    //           Math.random() * 0.3 - 0.1, 
    //           Math.cos(angle) * rad
    //         )
    //       );

    //       this.floatingCrystalsData.push({
    //         pos,
    //         startT: Math.random() * 2.5,
    //       }); 
    //     }
    //   }

    //   for(let i = 0; i < crystalsN; i++) {
    //     let pos = this.floatingCrystalsData[i].pos.clone();
    //     pos.add(new Vector3(0,Math.sin(i + animTime) * 0.07,0));

    //     let startT = this.floatingCrystalsData[i].startT;

    //     this.createPartialCrystal(length, width, pos, scaleT, showT - startT, vertices);
    //   }
    // }








    this.geo.setAttribute("position", new BufferAttribute(new Float32Array(vertices), 3));
  }
}