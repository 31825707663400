import * as THREE from "three";
import { MeshBasicMaterial } from "three";
import { FloatType, WebGLRenderTarget } from "three";
import { Vector3, Vector2 } from "three";

export default class ComputeFog {
  constructor(renderer, width, height, torusesScene, torusesSceneCamera, positionRT, blitProgram) {
    this.rt0 = new WebGLRenderTarget(
      Math.floor(width * 0.25),
      Math.floor(height * 0.25),
      { type: FloatType }
    );
    this.rt1 = new WebGLRenderTarget(
      Math.floor(width * 0.25),
      Math.floor(height * 0.25),
      { type: FloatType }
    );

    // this.fogMaskRT = new WebGLRenderTarget(width, height);

    this.torusesScene = torusesScene;
    this.torusesSceneCamera = torusesSceneCamera;
    this.blitProgram = blitProgram;

    this.torusesMaterial = new THREE.ShaderMaterial({
      vertexShader: `
      varying vec3 vWorldPos;

      void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);    
        vWorldPos = (modelMatrix * vec4(position, 1.0)).xyz;
      }
      `,

      fragmentShader: `
      varying vec3 vWorldPos;
   
      void main() {   
        if(vWorldPos.y < -0.46) discard;

        gl_FragColor = vec4(1.0);
      }
      `
    });
      
    this.blurMaterial = new THREE.ShaderMaterial({
      uniforms: {
        uTexture: { type: "t", value: null },
        uHorizontal: { value: false },
        uStep: { value: 1 },
        uPixelStep: { value: new Vector2(1 / width, 1 / height) },
      },

      vertexShader: `
      varying vec2 vUv;

      void main() {
          vUv = uv;
          gl_Position = vec4(position.xy, 0.0, 1.0);    
      }
      `,

      fragmentShader: `
      uniform sampler2D uTexture;

      uniform vec2 uPixelStep;
      uniform bool uHorizontal;
      uniform float uStep;

      varying vec2 vUv;

      float weight[5] = float[] (0.227027, 0.1945946, 0.1216216, 0.054054, 0.016216);

      void main() {   
          float blurRadius = 2.0 + uStep * 9.5;

          vec3 accum = vec3(0.0);
          for(int i = -4; i <= +4; i++) {
              vec2 offs = vec2(0.0); 

              if(uHorizontal)  offs = vec2(uPixelStep.x * float(i) * blurRadius, 0.0);
              if(!uHorizontal) offs = vec2(0.0, uPixelStep.x * float(i) * blurRadius);

              vec3 value = texture2D(uTexture, vUv + offs).xyz;
              
              vec3 weightedValue = vec3(0.0);
              if(i < 0) weightedValue = value * weight[abs(i)];
              if(i > 0) weightedValue = value * weight[i];
              if(i == 0) weightedValue = value * weight[0];

              accum += weightedValue * 0.7;

              vec3 value2 = texture2D(uTexture, vUv + offs * 0.2).xyz;
              vec3 weightedValue2 = vec3(0.0);
              if(i < 0) weightedValue2 = value2 * weight[abs(i)];
              if(i > 0) weightedValue2 = value2 * weight[i];
              if(i == 0) weightedValue2 = value2 * weight[0];
              accum += weightedValue2 * 0.3;


          }
          
          gl_FragColor = vec4(accum, 1.0);
      }
            `,

      depthTest: false,
      depthWrite: false,
    });

    this.fogMaterial = new THREE.ShaderMaterial({
      uniforms: {
        // uFogMask: { type: "t", value: this.fogMaskRT.texture },
        uColorTexture: { type: "t", value: null },
        uPositionTexture: { type: "t", value: positionRT.texture },
        uTorusesBloom: { type: "t", value: null },
        uCameraPos: { value: new Vector3(0,0,0) },
        uTime: { value: 0 },
        uWind: { value: 0 },
        uFogStartOffset: { value: 0 },
      },

      vertexShader: `
      varying vec2 vUv;

      void main() {
          vUv = uv;
          gl_Position = vec4(position.xy, 0.0, 1.0);    
      }
      `,

      fragmentShader: `
      varying vec2 vUv;
      
      uniform sampler2D uColorTexture;
      uniform sampler2D uTorusesBloom;
      uniform sampler2D uPositionTexture;
      // uniform sampler2D uFogMask;
      
      uniform vec3 uCameraPos;
      uniform float uTime;
      uniform float uWind;
      uniform float uFogStartOffset;

    /* discontinuous pseudorandom uniformly distributed in [-0.5, +0.5]^3 */
vec3 random3(vec3 c) {
	float j = 4096.0*sin(dot(c,vec3(17.0, 59.4, 15.0)));
	vec3 r;
	r.z = fract(512.0*j);
	j *= .125;
	r.x = fract(512.0*j);
	j *= .125;
	r.y = fract(512.0*j);
	return r-0.5;
}

const float F3 =  0.3333333;
const float G3 =  0.1666667;

/* 3d simplex noise */
float simplex3d(vec3 p) {
	 vec3 s = floor(p + dot(p, vec3(F3)));
	 vec3 x = p - s + dot(s, vec3(G3));
	 vec3 e = step(vec3(0.0), x - x.yzx);
	 vec3 i1 = e*(1.0 - e.zxy);
	 vec3 i2 = 1.0 - e.zxy*(1.0 - e);
	 vec3 x1 = x - i1 + G3;
	 vec3 x2 = x - i2 + 2.0*G3;
	 vec3 x3 = x - 1.0 + 3.0*G3;
	 vec4 w, d;
	 w.x = dot(x, x);
	 w.y = dot(x1, x1);
	 w.z = dot(x2, x2);
	 w.w = dot(x3, x3);
	 w = max(0.6 - w, 0.0);
	 d.x = dot(random3(s), x);
	 d.y = dot(random3(s + i1), x1);
	 d.z = dot(random3(s + i2), x2);
	 d.w = dot(random3(s + 1.0), x3);
	 w *= w;
	 w *= w;
	 d *= w;
	 return dot(d, vec4(52.0));
}
const mat3 rot1 = mat3(-0.37, 0.36, 0.85,-0.14,-0.93, 0.34,0.92, 0.01,0.4);
const mat3 rot2 = mat3(-0.55,-0.39, 0.74, 0.33,-0.91,-0.24,0.77, 0.12,0.63);
const mat3 rot3 = mat3(-0.71, 0.52,-0.47,-0.08,-0.72,-0.68,-0.7,-0.45,0.56);
float simplex3d_fractal(vec3 m) {
    return   0.5333333*simplex3d(m*rot1)
			+0.2666667*simplex3d(2.0*m*rot2)
			+0.1333333*simplex3d(4.0*m*rot3)
			+0.0666667*simplex3d(8.0*m);
}

vec3 hueShift( vec3 color, float hueAdjust ){
  const vec3  kRGBToYPrime = vec3 (0.299, 0.587, 0.114);
  const vec3  kRGBToI      = vec3 (0.596, -0.275, -0.321);
  const vec3  kRGBToQ      = vec3 (0.212, -0.523, 0.311);
  const vec3  kYIQToR     = vec3 (1.0, 0.956, 0.621);
  const vec3  kYIQToG     = vec3 (1.0, -0.272, -0.647);
  const vec3  kYIQToB     = vec3 (1.0, -1.107, 1.704);
  float   YPrime  = dot (color, kRGBToYPrime);
  float   I       = dot (color, kRGBToI);
  float   Q       = dot (color, kRGBToQ);
  float   hue     = atan (Q, I);
  float   chroma  = sqrt (I * I + Q * Q);
  hue += hueAdjust;
  Q = chroma * sin (hue);
  I = chroma * cos (hue);
  vec3    yIQ   = vec3 (YPrime, I, Q);
  return vec3( dot (yIQ, kYIQToR), dot (yIQ, kYIQToG), dot (yIQ, kYIQToB) );
}

      void main() {   
        vec3 color = texture2D(uColorTexture, vUv).xyz;
        vec3 torusesColor = texture2D(uTorusesBloom, vUv).xyz;
        vec3 position = texture2D(uPositionTexture, vUv).xyz;
        // float fogMask = 1.0 - texture2D(uFogMask, vUv).x;

        if(position == vec3(0.0)) position = vec3(100.0);
     
        // float distFromCenter = length(position);
        // if(distFromCenter > 3.5) {
        //   float t = (distFromCenter - 3.5) / 2.0;
        //   position += normalize(position - uCameraPos) * 20.0 * t;
        // }

        vec3 dir = normalize(uCameraPos - position);
        float distance = length(uCameraPos - position);
        float particles = 0.0;
        int steps = 10;
        float fogStart = 3.5 + uFogStartOffset;
        vec3 rd = dir;
        vec3 ro = uCameraPos + rd * fogStart;
        if(distance > fogStart) {
          float stepDistance = (distance - fogStart) / float(steps);
          for(int i = 0; i < steps; i++) {
            float dist = stepDistance * float(i);
            vec3 p = ro + rd * (fogStart + dist);

            float nv = (simplex3d_fractal(p * 0.16 + vec3(uTime * 0.1 + uWind, 0.0, 0.0)) + 0.25 );
            nv = clamp(nv, 0.0, 1.0);
            nv += dist * 0.01;

            particles += nv * stepDistance * 18.0;
          }
        }

        float fogFactor = 1.0 - exp(-particles * 0.1);
        color = mix(color, vec3(1.0, 0.6, 0.4) * 0.25, fogFactor);
        // color = mix(color, vec3(1.0, 0.7, 0.5) * 0.25, fogFactor);

        torusesColor *= vec3(1.0, 0.3, 0.15) * 8.0;
        torusesColor = ACESFilmicToneMapping(torusesColor);

        float Talpha = torusesColor.x;
        // Talpha *= fogMask;

        // vec3 finalColor = mix(color, torusesColor, Talpha) * 0.5 + (color + torusesColor) * 0.5;
        // vec3 finalColor = hueShift(color, -0.05) + torusesColor * 0.75;
        vec3 finalColor = color + torusesColor * 0.5;

        gl_FragColor = vec4(finalColor, 1.0);
      }
      `,

      depthTest: false,
      depthWrite: false,
    });

    this.blackMaterial = new MeshBasicMaterial({ color: 0x0 });
    this.mesh = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(2, 2),
      this.blurMaterial
    );
    this.camera = new THREE.PerspectiveCamera(
      45,
      1 /* remember that the camera is worthless here */,
      1,
      1000
    );
    this.renderer = renderer;

    this.scene = new THREE.Scene();
    this.scene.add(this.mesh);
 
    this.clock = new THREE.Clock();
    this.clock.start();
  }

  setWindLevel(windValue /* [0...deltatime] */, fogStartOffset /* [0...1] */) {
    this.fogMaterial.uniforms.uWind.value += windValue;
    this.fogMaterial.uniforms.uFogStartOffset.value = fogStartOffset * -1.0;
  }

  compute(colorTexture, renderTargetDest) {
    this.renderer.setRenderTarget(this.rt1);
    this.torusesScene.overrideMaterial = this.torusesMaterial;
    this.renderer.render(this.torusesScene, this.torusesSceneCamera);
    this.torusesScene.overrideMaterial = null;
    
    this.mesh.material = this.blurMaterial;
    // ping pong blur toruses scene
    for(let i = 0; i < 7; i++) {
      this.blurMaterial.uniforms.uStep.value = i;

      if(i > 5) this.blurMaterial.uniforms.uStep.value = 1;

      // horizontal pass
      this.renderer.setRenderTarget(this.rt0);
      this.blurMaterial.uniforms.uHorizontal.value = true;
      this.blurMaterial.uniforms.uTexture.value = this.rt1.texture;
      this.renderer.render(this.scene, this.camera);

      // vertical pass
      this.renderer.setRenderTarget(this.rt1);
      this.blurMaterial.uniforms.uHorizontal.value = false;
      this.blurMaterial.uniforms.uTexture.value = this.rt0.texture;
      this.renderer.render(this.scene, this.camera);
    }


    this.fogMaterial.uniforms.uColorTexture.value = colorTexture;
    this.fogMaterial.uniforms.uTorusesBloom.value = this.rt1.texture;
    this.fogMaterial.uniforms.uCameraPos.value = this.torusesSceneCamera.position.clone();
    this.fogMaterial.uniforms.uTime.value = this.clock.getElapsedTime();
    this.mesh.material = this.fogMaterial;
    this.renderer.setRenderTarget(renderTargetDest);
    this.renderer.render(this.scene, this.camera);
    this.renderer.setRenderTarget(null);
  }
}
