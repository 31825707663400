import { ShaderChunk } from "three";

export function last(array) {
  return array[array.length - 1];
}

export function parseIncludes(string) {
  var utils_includepattern = /#include <(.*)>/gm;

  function replace(match, include) {
    var replace = ShaderChunk[include];
    return parseIncludes(replace);
  }

  return string.replace(utils_includepattern, replace);
}

let onceMemory = {};
export function once(tag) {
  if (!onceMemory[tag]) {
    onceMemory[tag] = true;
    return true;
  }

  return false;
}
