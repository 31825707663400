import { parseIncludes } from "../utils";

export function ReflectableMaterial(material) {
  material.userData.uReflect = { value: false };
  material.userData.uReflectPositionPass = { value: false };
  material.reflectable = true;

  material.onBeforeCompile = (shader) => {
    let prependUniforms = [
      "uniform bool uReflect;\n",
      "uniform bool uReflectPositionPass;\n",
      "varying vec3 vReflectedPos;\n"
    ].join("\n");

    let prependDefines = "";


    // let val = "0.460000";
    let val1 = "+ 0.92";
    let val2 = "- 0.46";

    shader.uniforms.uReflect = material.userData.uReflect;
    shader.uniforms.uReflectPositionPass = material.userData.uReflectPositionPass;

    shader.vertexShader = parseIncludes(shader.vertexShader); 
    shader.vertexShader = shader.vertexShader.replace(
      "mvPosition = modelViewMatrix * mvPosition;",
      `
        mvPosition = modelMatrix * mvPosition;
        if(uReflect) {
          mvPosition.y = -(mvPosition.y ${val1});
        }
        vReflectedPos = mvPosition.xyz;
        mvPosition = viewMatrix * mvPosition;
      `,
    );
    shader.vertexShader = prependDefines + prependUniforms + shader.vertexShader;

    shader.fragmentShader = parseIncludes(shader.fragmentShader); 
    shader.fragmentShader = shader.fragmentShader.replace(
      "gl_FragColor = linearToOutputTexel( gl_FragColor );",
      `
      gl_FragColor = linearToOutputTexel( gl_FragColor );
      if(uReflect && vReflectedPos.y > ${val2}) {
        discard;
      }

      if(uReflectPositionPass) {
        gl_FragColor = vec4(vReflectedPos, 1.0);
        return;
      }
      `,
    );
    shader.fragmentShader = prependDefines + prependUniforms + shader.fragmentShader;
  };

  return material;
}