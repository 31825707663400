import { DoubleSide, Scene, SphereBufferGeometry } from "three";
import { MeshBasicMaterial } from "three";
import { Vector3 } from "three";
import { Mesh } from "three";
import { Raycaster } from "three";
import { Vector2 } from "three";
import { once } from "../utils";

export function initCentralRaycast(canvas, camera, onClick) {
  const raycaster = new Raycaster();
  const placeholderScene = new Scene();

  const centralSphereGeo = new SphereBufferGeometry(0.32, 6, 6);
  const centralSphere = new Mesh(centralSphereGeo, new MeshBasicMaterial({
    color: 0xffffff,
    side: DoubleSide,
  }));
  centralSphere.position.set(0.71, -0.13, -0.05);
  centralSphere.updateMatrixWorld();

  placeholderScene.add(centralSphere);


  let hover = false;
  let clickPt;

  window.addEventListener("mousedown", (e) => {
    clickPt = new Vector2(
      ( e.clientX / window.innerWidth ) * 2 - 1,
      - ( e.clientY / window.innerHeight ) * 2 + 1,
    );

    centralCursorEl.classList.add("active");
    outerCursorEl.classList.add("active");
  });
  window.addEventListener("mouseup", (e) => {
    let upClickPt = new Vector2(
      ( e.clientX / window.innerWidth ) * 2 - 1,
      - ( e.clientY / window.innerHeight ) * 2 + 1,
    );

    // if we didn't move the mouse around and this is an actual click,
    // notify listeners
    if(clickPt.clone().sub(upClickPt).length() < 0.01) {
      onClick(hover);
    }

    centralCursorEl.classList.remove("active");
    outerCursorEl.classList.remove("active");
  });

  let outerCursorPos = new Vector2(window.innerWidth * 0.5, window.innerHeight * 0.75);
  let newCursorPos = new Vector2(window.innerWidth * 0.5, window.innerHeight * 0.75);

  window.addEventListener("mousemove", (e) => {
    let mouse = new Vector2(
      ( e.clientX / window.innerWidth ) * 2 - 1,
      - ( e.clientY / window.innerHeight ) * 2 + 1,
    );
    // update the picking ray with the camera and mouse position
    raycaster.setFromCamera( mouse, camera );

    newCursorPos.setX(e.clientX);
    newCursorPos.setY(e.clientY);

    // calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects( placeholderScene.children );
    if(intersects[0]) {

      if(once("hide-mouse-icon")) {
        setTimeout(() => {
          mouseIconEl.classList.add("hide");
          
          setTimeout(() => {
            mouseIconEl.style.display = "none";
          }, 500);
        }, 250);
      }

      hover = true;
      if(!outerCursorEl.classList.contains("hover")) {
        outerCursorEl.classList.add("hover");
      }

    } else {
      hover = false;
      if(outerCursorEl.classList.contains("hover")) {
        outerCursorEl.classList.remove("hover");
      }
    }
  });


  let centralCursorEl = document.querySelector(".central-cursor");
  let outerCursorEl = document.querySelector(".outer-cursor");
  let mouseIconEl = document.querySelector(".mouse-icon");

  let ccr = centralCursorEl.getBoundingClientRect();
  let ocr = outerCursorEl.getBoundingClientRect();
  let mcr = mouseIconEl.getBoundingClientRect();

  function update(deltatime) {
    // exponential average outerCursor's pos
    let exponentialAverageFactor = 0.8;
    outerCursorPos.multiplyScalar(exponentialAverageFactor).add(newCursorPos.clone().multiplyScalar(1 - exponentialAverageFactor));

    centralCursorEl.style.left = `${newCursorPos.x - ccr.width * 0.5}px`;
    centralCursorEl.style.top  = `${newCursorPos.y - ccr.height * 0.5}px`;

    outerCursorEl.style.left = `${outerCursorPos.x - ocr.width * 0.5}px`;
    outerCursorEl.style.top  = `${outerCursorPos.y - ocr.height * 0.5}px`;

    // central point projection
    // let ndcMousePos = new Vector3(0.71, -0.13, -0.05).project(camera);
    let ndcMousePos = new Vector3(0.87, 0, -0.15).project(camera);
    ndcMousePos.multiplyScalar(0.5).addScalar(0.5).multiply(new Vector3(window.innerWidth, window.innerHeight, 0));

    mouseIconEl.style.left = `${ndcMousePos.x - mcr.width * 0.5}px`;
    mouseIconEl.style.top  = `${window.innerHeight - (ndcMousePos.y - mcr.height * 0.5)}px`;
  }

  // public API
  return {
    update
  }
}