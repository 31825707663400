import { CustomToneMapping, ShaderChunk } from "three";
import { BufferGeometry } from "three";
import { Clock } from "three";
import { WebGLMultisampleRenderTarget } from "three";
import { ACESFilmicToneMapping, BoxBufferGeometry, Color, CubeCamera, DoubleSide, FloatType, GammaEncoding, LinearEncoding, LinearFilter, LinearMipmapLinearFilter, Mesh, MeshBasicMaterial, MeshPhysicalMaterial, MeshStandardMaterial, NearestFilter, PerspectiveCamera, PlaneBufferGeometry, PMREMGenerator, RGBFormat, Scene, ShaderMaterial, SphereBufferGeometry, sRGBEncoding, TextureLoader, Vector2, Vector3, WebGLCubeRenderTarget, WebGLRenderer, WebGLRenderTarget } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import { smoothstep } from "three/src/math/MathUtils";
import Blit from "./graphics-components/blit";
import BlitDepth from "./graphics-components/blitDepth";
import BlitNormals from "./graphics-components/blitNormals";
import BlitPosition from "./graphics-components/blitPosition";
import BlitReflectedColor from "./graphics-components/blitReflectedColorRT";
import BlitReflectionDistance from "./graphics-components/blitReflectionDistance";
import BlurReflectionDistance from "./graphics-components/blurReflectionDistance";
import CentralParticles from "./graphics-components/centralParticles";
import ComputeFog from "./graphics-components/computeFog";
import Crystals from "./graphics-components/crystals";
import { framebuffers as fluidSimFramebuffers, initFluidSim, initMouseCommands, updateFluidSim } from "./graphics-components/fluidsim";
import LightGrid from "./graphics-components/lightGrid";
import { initCentralRaycast } from "./graphics-components/mainRaycast";
import PostProcess from "./graphics-components/postProcess";
import { linterp, rand, remap, saturate, spectrum_offset } from "./graphics-components/shaderFragments";
import { mountainsMaterial } from "./materials/mountains";
import { ReflectableMaterial } from "./materials/reflectable";
import { WaterMaterial } from "./materials/water";
import { last, once } from "./utils";

let rnd  = (v = 1) => Math.random() * v;
let nrnd = (v = 1) => (Math.random() * 2 - 1) * v;

let width = window.innerWidth;
let height = window.innerHeight;

let renderer;
let controls;
let scene  = new Scene();
let camera = new PerspectiveCamera( 40, width / height, 0.1, 1000 );
let blitProgram;
let mainFramebuffer = new WebGLMultisampleRenderTarget(width, height, { });
mainFramebuffer.texture.encoding = sRGBEncoding;

let lightGrid;
let clock;
let crystals;

// **************** this block can be deleted I think **************
let displayProgram = new ShaderMaterial({
  vertexShader: `
    varying vec2 vUv;

    void main() {
      gl_Position = vec4(position.xy, 0.0, 1.0); 
      vUv = uv;
    }
  `,

  fragmentShader: `
  varying vec2 vUv;
  
  uniform sampler2D uSceneTex;
  uniform sampler2D uFluidVelocityTex;
  uniform sampler2D uFluidDyeTex;

  ${saturate}
  ${remap}
  ${linterp}
  ${spectrum_offset}
  ${rand}
  
  void main() {

    vec4 fluidVel = -texture2D(uFluidVelocityTex, vUv);
    vec4 fluidDye = texture2D(uFluidDyeTex, vUv);


    float velStrength = 0.00002;
    velStrength = 0.0;
    float dyeStrength = 0.01;

    
    vec2 uvOffset1 = fluidVel.xy * velStrength;
    vec2 uvOffset2 = fluidDye.xy * dyeStrength;
    vec2 uvOffset = uvOffset1 + uvOffset2;

    vec4 accum = vec4(0.0);
    vec3 soAccum = vec3(0.0);

    int steps = 20;
    float fsteps = float(steps);
    float invSteps = 1.0 / float(steps);

    for(int i = 0; i < steps; i++) {
      vec2 transformedUv = vUv + uvOffset * ((float(i) * invSteps) * fsteps * 0.85 + fsteps * 0.15);
      uvOffset *= (1.0 + srand(transformedUv + float(i) * 0.1987) * 0.05);
      vec4 color = texture2D(uSceneTex, transformedUv);

      vec3 so = spectrum_offset(float(i) * invSteps);
      soAccum += so;

      accum += color * vec4(so, 1.0);
    }

    accum.rgb /= soAccum;
    accum.a = 1.0;


    gl_FragColor = accum;
    // gl_FragColor = fluidVel;


    // vec4 fluidVel2 = texture2D(uFluidVelocityTex, vUv);
    // gl_FragColor = fluidVel2;
  }
  `,

  uniforms: {
    uSceneTex: { type: "t", value: null },
    uFluidVelocityTex: { type: "t", value: null },
    uFluidDyeTex: { type: "t", value: null },
  },

  depthTest:  false,
  depthWrite: false,
});
let quad = new Mesh(new PlaneBufferGeometry(2,2), displayProgram);
let quadCamera = new PerspectiveCamera( 45, 1 /* remember that the camera is worthless here */, 1, 1000 );
let quadScene = new Scene();
quadScene.add(quad);
// **************** this block can be deleted I think **************


let planeScene = new Scene();

let whiteRocks = [];
let plane;
let sphere;

let mountainsDepthScene = new Scene();
let mountainsDepthRT = new WebGLRenderTarget(width, height, { type: FloatType });
let waterScene = new Scene();
let waterDepthRT = new WebGLRenderTarget(width, height, { type: FloatType });
let depthProgram;

let bundledTorusesScene = new Scene();

let cursorAPI;

let colorRT = new WebGLRenderTarget(width, height, { 
});
colorRT.texture.encoding = sRGBEncoding;
let postProcessBuffer = new WebGLRenderTarget(width, height, { depthBuffer: false, stencilBuffer: false });
let reflectedColorRT = new WebGLRenderTarget(width, height, { 
  // type: FloatType, 
  minFilter: LinearMipmapLinearFilter, 
  magFilter: LinearFilter 
});
reflectedColorRT.texture.encoding = sRGBEncoding;
reflectedColorRT.texture.generateMipmaps = true;
let reflectionPositionRT = new WebGLRenderTarget(width, height, { 
  type: FloatType, 
  minFilter: NearestFilter,
  magFilter: NearestFilter,
});
let reflectionDistanceRT = new WebGLRenderTarget(width, height, { 
  type: FloatType, 
  minFilter: NearestFilter,
  magFilter: NearestFilter,
});
let blurDistanceRT = new WebGLRenderTarget(width, height, { 
  type: FloatType, 
  minFilter: NearestFilter,
  magFilter: NearestFilter,
});
// let normalRT = new WebGLRenderTarget(width, height, { type: FloatType });
let positionRT = new WebGLRenderTarget(width, height, { 
  type: FloatType, 
  // minFilter: LinearMipmapLinearFilter, 
  // magFilter: LinearFilter
  
  // if we don't use nearest filter (FOR BOTH!!), when doing a binary search between two pixels that have widly different depths,
  // we could narrow down the research enough to interpolate to one of the other pixels and screw up all calculations
  // interpolation between those two pixels could be very problematic and show artifacts
  minFilter: NearestFilter,
  magFilter: NearestFilter,
});
let planePositionRT = new WebGLRenderTarget(width, height, { 
  type: FloatType, 
  minFilter: NearestFilter,
  magFilter: NearestFilter,
});
// positionRT.texture.mipmaps = true;
// positionRT.texture.generateMipmaps = true;
// let normalProgram;
let positionProgram;
let reflectionDistanceProgram;
let blurReflectionDistanceProgram;
let blitReflectedColorProgram;
let computeFogProgram;
let postProcessProgram;

console.log("TODO: resize rts on window resize");
console.log("TODO: resize water material on window resize");
console.log("TODO: resize gbuffers");

let particles = new CentralParticles(camera, waterScene);

let cameraInitPosStart = new Vector3(4.389916588536426, 3.0581657791499914, -2.81715742168022);
let cameraInitPosEnd   = new Vector3( 3.5972306534901795, 1.2510861359705123, -2.010063762495709);
let cameraPositionAnimT = 0;
let curtainEl;

export async function initThree(canvas) {
  renderer = new WebGLRenderer({ canvas, antialias: true });
  // renderer.autoClear = false;
  renderer.setSize( width, height );
  // renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMapping = CustomToneMapping;
  renderer.outputEncoding = sRGBEncoding; 


      /*
      vec3 ACESFilmicToneMapping( vec3 color ) {
        const mat3 ACESInputMat = mat3(
        vec3( 0.59719, 0.07600, 0.02840 ), vec3( 0.35458, 0.90834, 0.13383 ), vec3( 0.04823, 0.01566, 0.83777 )
        );
        const mat3 ACESOutputMat = mat3(
        vec3(  1.60475, -0.10208, -0.00327 ), vec3( -0.53108, 1.10813, -0.07276 ), vec3( -0.07367, -0.00605, 1.07602 )
        );
        color *= toneMappingExposure / 0.6;
        color = ACESInputMat * color;
        color = RRTAndODTFit( color );
        color = ACESOutputMat * color;
        return saturate( color );
      }
      */

  ShaderChunk.tonemapping_pars_fragment = ShaderChunk.tonemapping_pars_fragment.replace(
    'vec3 CustomToneMapping( vec3 color ) { return color; }',

    // `#define Uncharted2Helper( x ) max( ( ( x * ( 0.15 * x + 0.10 * 0.50 ) + 0.20 * 0.02 ) / ( x * ( 0.15 * x + 0.50 ) + 0.20 * 0.30 ) ) - 0.02 / 0.30, vec3( 0.0 ) )
    // float toneMappingWhitePoint = 1.0;
    // vec3 CustomToneMapping( vec3 color ) {
    //   color *= toneMappingExposure;
    //   return saturate( Uncharted2Helper( color ) / Uncharted2Helper( vec3( toneMappingWhitePoint ) ) );
    // }`

    `
    vec3 CustomToneMapping( vec3 color ) {
      const mat3 ACESInputMat = mat3(
        vec3( 0.59719, 0.07600, 0.02840 ), vec3( 0.35458, 0.90834, 0.13383 ), vec3( 0.04823, 0.01566, 0.83777 )
        );
        const mat3 ACESOutputMat = mat3(
        vec3(  1.60475, -0.10208, -0.00327 ), vec3( -0.53108, 1.10813, -0.07276 ), vec3( -0.07367, -0.00605, 1.07602 )
        );
        color *= toneMappingExposure / 0.6;
        color = ACESInputMat * color;
        color = RRTAndODTFit( color );
        color = ACESOutputMat * color;
        return saturate( color );
    }`
  );


  controls = new OrbitControls(camera, canvas);
  controls.target.set(
    0.38096045206150597,
    -0.6850691022701711,
    0.2873385380788971);
  controls.enableDamping = true;
  controls.dampingFactor = 0.05;
  controls.rotateSpeed = 0.5;
  controls.enableZoom = false;
  controls.enablePan = false;
  controls.enableRotate = false;
  controls.maxPolarAngle = Math.PI * 0.4;

  // camera.position.set(
  //   3.5972306534901795,
  //   1.2510861359705123,
  //   -2.010063762495709,
  // );
  camera.position.copy(cameraInitPosStart);

  curtainEl = document.querySelector(".curtain");
  let curtainPEl = curtainEl.querySelector("p");

  let freeCamera = false;
  document.querySelector(".free-camera-btn").addEventListener("click", () => {
    freeCamera = !freeCamera;
    if(freeCamera) {
      controls.enableZoom = true;
      controls.enablePan = true;
      controls.enableRotate = true;
      controls.maxPolarAngle = Math.PI;
      document.querySelector(".free-camera-btn p").innerHTML = "Free camera<br>ON";
    } else {
      controls.enableZoom = false;
      controls.enablePan = false;
      controls.enableRotate = true;
      controls.maxPolarAngle = Math.PI * 0.4;
      document.querySelector(".free-camera-btn p").innerHTML = "Free camera<br>OFF";
    }
  });

  blitProgram = new Blit(renderer);
  depthProgram = new BlitDepth(renderer, null, camera);
  // normalProgram = new BlitNormals(renderer, null, camera);
  positionProgram = new BlitPosition(renderer, null, camera);
  reflectionDistanceProgram = new BlitReflectionDistance(renderer);
  blurReflectionDistanceProgram = new BlurReflectionDistance(renderer, window.innerWidth, window.innerHeight);
  blitReflectedColorProgram = new BlitReflectedColor(renderer);
  postProcessProgram = new PostProcess(renderer, positionRT);

  curtainPEl.textContent = "downloading assets 01 / 12";
  let mountainsScene = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/mountains.glb")).scene;
  let groups = mountainsScene.children[0].children[0].children[0].children;

  curtainPEl.textContent = "downloading assets 02 / 12";
  let isle = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/isle.glb")).scene;
  isle = isle.children[0];

  curtainPEl.textContent = "downloading assets 03 / 12";
  let toruses = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/toruses.glb")).scene;
  
  curtainPEl.textContent = "downloading assets 04 / 12";
  plane = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/plane.glb")).scene.children[0];
  

  curtainPEl.textContent = "downloading assets 05 / 12";
  let mountainsText = await new TextureLoader().loadAsync(process.env.PUBLIC_URL + "/assets/textures/baketest6-1000s.jpg");
  mountainsText.flipY = false;
  mountainsText.encoding = sRGBEncoding;

  curtainPEl.textContent = "downloading assets 06 / 12";
  let isleText = await new TextureLoader().loadAsync(process.env.PUBLIC_URL + "/assets/textures/islebake3-1000s.png");
  isleText.encoding = sRGBEncoding;
  isleText.flipY = false;
  
  // isle
  isle.material = ReflectableMaterial(new MeshBasicMaterial({ color: 'white', side: DoubleSide, map: isleText, toneMapped: false }));
  isle.name = "isle-group";
  scene.add(isle.clone());
  mountainsDepthScene.add(isle.clone());


  // rock1
  curtainPEl.textContent = "downloading assets 07 / 12";
  let rock1 = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/rock1.glb")).scene.children[0];
  let rock1Text = await new TextureLoader().loadAsync(process.env.PUBLIC_URL + "/assets/textures/rock1.png");
  rock1Text.flipY = false;
  rock1Text.encoding = sRGBEncoding;
  rock1.material = ReflectableMaterial(new MeshBasicMaterial({ color: 'white', side: DoubleSide, map: rock1Text, toneMapped: false }));
  whiteRocks.push(rock1.clone());
  scene.add(last(whiteRocks));

  // rock2
  curtainPEl.textContent = "downloading assets 08 / 12";
  let rock2 = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/rock2.glb")).scene.children[0];
  let rock2Text = await new TextureLoader().loadAsync(process.env.PUBLIC_URL + "/assets/textures/rock2.png");
  rock2Text.flipY = false;
  rock2Text.encoding = sRGBEncoding;
  rock2.material = ReflectableMaterial(new MeshBasicMaterial({ color: 'white', side: DoubleSide, map: rock2Text, toneMapped: false }));
  whiteRocks.push(rock2.clone());
  scene.add(last(whiteRocks));

  // rock3
  curtainPEl.textContent = "downloading assets 09 / 12";
  let rock3 = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/rock3.glb")).scene.children[0];
  let rock3Text = await new TextureLoader().loadAsync(process.env.PUBLIC_URL + "/assets/textures/rock3.png");
  rock3Text.flipY = false;
  rock3Text.encoding = sRGBEncoding;
  rock3.material = ReflectableMaterial(new MeshBasicMaterial({ color: 'white', side: DoubleSide, map: rock3Text, toneMapped: false }));
  whiteRocks.push(rock3.clone());
  scene.add(last(whiteRocks));

  // rock4
  curtainPEl.textContent = "downloading assets 10 / 12";
  let rock4 = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/rock4.glb")).scene.children[0];
  let rock4Text = await new TextureLoader().loadAsync(process.env.PUBLIC_URL + "/assets/textures/rock4.png");
  rock4Text.flipY = false;
  rock4Text.encoding = sRGBEncoding;
  rock4.material = ReflectableMaterial(new MeshBasicMaterial({ color: 'white', side: DoubleSide, map: rock4Text, toneMapped: false }));
  scene.add(rock4.clone());

  // astronaut
  curtainPEl.textContent = "downloading assets 11 / 12";
  let astronaut = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/astronaut.glb")).scene.children[0];
  let astronautText = await new TextureLoader().loadAsync(process.env.PUBLIC_URL + "/assets/textures/astronaut.png");
  astronautText.flipY = false;
  astronautText.encoding = sRGBEncoding;
  astronaut.material = ReflectableMaterial(new MeshBasicMaterial({ color: 'white', side: DoubleSide, map: astronautText, toneMapped: false }));
  scene.add(astronaut.clone());

  // mountains
  for(let i = 0; i < groups.length; i++) {
    let g = groups[i];

    g.traverse((o) => {
      if(o instanceof Mesh) {
        o.material = ReflectableMaterial(new MeshBasicMaterial({ 
          color: 'white', 
          side: DoubleSide, 
          map: mountainsText,
          toneMapped: false, 
        }));

        o.name = "mountain-group";

        o.scale.set(9.426517486572266, 9.426517486572266, 9.426517486572266);

        o.translateX(+0.00046069626114331186 * 9.426517486572266);
        o.translateY(-0.096357062458992 * 9.426517486572266);
        o.translateZ(+0.09635782241821289 * 9.426517486572266);

        o.rotateX(-1.5707958795918522);
        o.rotateY(-0.00478110738920276);
        o.rotateZ(0.00009598708991624814);


        o.updateMatrix();
        o.geometry.applyMatrix( o.matrix );
        o.position.set( 0, 0, 0 );
        o.rotation.set( 0, 0, 0 );
        o.scale.set( 1, 1, 1 );
        o.updateMatrix();
      }
    });

    scene.add(g.clone());
    mountainsDepthScene.add(g.clone());
  };

  // lightGrid = new LightGrid(groups);
  // scene.add(lightGrid.mesh);
  
  // toruses
  let torusesGeometries = [];
  toruses.children.forEach((g, i) => {
    g.traverse((o) => {
      if(o instanceof Mesh) {
        o.material = ReflectableMaterial(new MeshStandardMaterial({ 
          emissive: new Color('#FF5624'), 
          emissiveIntensity: i === 0 ? 20 : 10,
          side: DoubleSide,
          toneMapped: false,
        }));

        o.updateMatrix();
        o.geometry.applyMatrix( o.matrix );
        o.position.set( 0, 0, 0 );
        o.rotation.set( 0, 0, 0 );
        o.scale.set( 1, 1, 1 );
        o.updateMatrix();

        torusesGeometries.push(o.geometry.clone());
      }
    })
    scene.add(g.clone());
  });
  let bundledTorusesGeometries = mergeBufferGeometries(torusesGeometries);
  let bundledToruses = new Mesh(bundledTorusesGeometries, new MeshBasicMaterial({ color: 0xffffff }));
  bundledTorusesScene.add(bundledToruses);

  computeFogProgram = new ComputeFog(renderer, window.innerWidth, window.innerHeight, bundledTorusesScene, camera, positionRT, blitProgram);

  // plane
  // plane.material = new MeshBasicMaterial({ color: 'black', side: DoubleSide, transparent: true, opacity: 0.7 });
  plane.material = WaterMaterial();
  plane.material.uniforms.uScreenSize.value = new Vector2(width, height);
  plane.scale.set(100, 1, 100);
  // new MeshPhysicalMaterial({ 
  //   color: new Color(0.5, 0.5, 0.5), 
  //   side: DoubleSide, 
  //   metalness: 0,
  //   roughness: 0.33,
  //   // ior: 1.5,
  //   // transmission: 1,
  //   // thickness: 0.01,
  //   opacity: 0.7, 
  //   transparent: true, 
  // });

  console.log(plane);
  // scene.add(plane.clone());
  waterScene.add(plane.clone());


  crystals = new Crystals();
  scene.add(crystals.mesh);


  const pmremGenerator = new PMREMGenerator( renderer );
  // pmremGenerator.compileCubemapShader();
  // let cubemapRT = pmremGenerator.fromCubemap(cubeRT.texture);
  scene.position.set(-0.699878, 0.134614, 0.036077);
  let cubemapRT = pmremGenerator.fromScene(scene);
  scene.position.set(0,0,0);
  


  // after PMREM does it's job, reassing mountains material
  // apparently it seems like PMREM didn't like my custom material
  scene.traverse((o) => {
    if(o.name == "mountain-group"){
      o.material = ReflectableMaterial(mountainsMaterial(mountainsText));
    }
    if(o.name == "isle-group"){
      o.material = ReflectableMaterial(mountainsMaterial(isleText));
    }
  });



  // reset toruses radiance data after PMREM runs
  toruses.children.forEach((g, i) => {
    g.traverse((o) => {
      if(o instanceof Mesh) {
        // o.material.emissiveIntensity = i === 0 ? 5 : 2.5;
        o.material.emissiveIntensity = i === 0 ? 4 : 2;
      }
    })
  });

  // sphere
  curtainPEl.textContent = "downloading assets 12 / 12";
  sphere = (await new GLTFLoader().loadAsync(process.env.PUBLIC_URL + "/assets/models/sphere.glb")).scene.children[0];
  let sphereText = await new TextureLoader().loadAsync(process.env.PUBLIC_URL + "/assets/textures/sphere.png");
  sphereText.flipY = false;
  // sphereText.encoding = sRGBEncoding;
  sphere.material = ReflectableMaterial(new MeshStandardMaterial({ 
    metalness: 0,
    // roughness: 0.185,  // set inside the loop function 
    envMap: cubemapRT.texture, 
    envMapIntensity: 1,
    side: DoubleSide,
    map: sphereText,
    // toneMapped: false,
  }));
  scene.add(sphere);

  curtainPEl.classList.add("hidden");
  
  // initFluidSim(renderer, canvas, {
  //   SIM_RESOLUTION: 256,
  //   DYE_RESOLUTION: 1024,
  //   CAPTURE_RESOLUTION: 512,
  //   DENSITY_DISSIPATION: 0.7, // 1,
  //   VELOCITY_DISSIPATION: 1.8,
  //   PRESSURE: 1,
  //   PRESSURE_ITERATIONS: 20,
  //   CURL: 14,
  //   SPLAT_RADIUS: 0.25,
  //   SPLAT_FORCE: 6000,
  //   SHADING: false,
  //   COLORFUL: true,
  //   COLOR_UPDATE_SPEED: 10,
  //   PAUSED: false,
  //   BACK_COLOR: { r: 0, g: 0, b: 0 },
  //   TRANSPARENT: false,
  // });
  initFluidSim(renderer, canvas, {
    SIM_RESOLUTION: 256,
    DYE_RESOLUTION: 512,
    CAPTURE_RESOLUTION: 256,
    DENSITY_DISSIPATION: 0.7, // 1,
    VELOCITY_DISSIPATION: 1.8,
    PRESSURE: 1,
    PRESSURE_ITERATIONS: 20,
    CURL: 14,
    SPLAT_RADIUS: 0.15,
    SPLAT_FORCE: 6000,
    SHADING: false,
    COLORFUL: true,
    COLOR_UPDATE_SPEED: 10,
    PAUSED: false,
    BACK_COLOR: { r: 0, g: 0, b: 0 },
    TRANSPARENT: false,
  });

  
  // we'll start the clock after the first render otherwise deltatime sucks because of compilation time of shaders
  clock = new Clock();

  initMouseCommands(camera, waterScene);
  cursorAPI = initCentralRaycast(canvas, camera, (hover) => {
    if(hover) {
      state.sphereActive = !state.sphereActive;
      state.sphereClickTimer = 0;
    }
  });

  // initFluidSim(renderer, canvas);

  renderer.setAnimationLoop(loop);
}

function reflectMaterials(scene) {
  scene.traverse((o) => {
    if(o instanceof Mesh && o.material.reflectable) {
      o.material.userData.uReflect.value = !o.material.userData.uReflect.value;
    }
  })
}

function setReflectPositionPass(scene, value) {
  scene.traverse((o) => {
    if(o instanceof Mesh && o.material.reflectable) {
      o.material.userData.uReflectPositionPass.value = value;
    }
  })
}

let state = {
  sphereActive: false,
  sphereActiveTimer: 0,
  sphereClickTimer: 999,  // resets back to zero after every click, continuously goes up
};

function loop(now) {
  now *= 0.001;

  let deltatime = clock.getDelta();
  let time = clock.getElapsedTime(); // + 20.5;

  cameraPositionAnimT += deltatime * 0.35;
  if(cameraPositionAnimT < 1) {
    camera.position.copy(cameraInitPosStart.clone().lerp(cameraInitPosEnd, smoothstep(cameraPositionAnimT, 0, 1)));
    curtainEl.style.opacity = Math.max(smoothstep(1 - cameraPositionAnimT * 1.3, 0, 1), 0);
  } else {
    if(once("camera anim finish")) {
      camera.position.copy(cameraInitPosEnd);
      curtainEl.remove();
      controls.enableRotate = true;
    }
  }

  controls.update();

  cursorAPI.update(deltatime);

  if(state.sphereActive && state.sphereActiveTimer < 2) {
    state.sphereActiveTimer += deltatime;
    // we'll let it grow past 1 to have better control on animation timing for postprocess effects
    // if(state.sphereActiveTimer > 1) state.sphereActiveTimer = 1;
  }
  if(!state.sphereActive && state.sphereActiveTimer > 0) {
    state.sphereActiveTimer -= deltatime;
    if(state.sphereActiveTimer < 0) state.sphereActiveTimer = 0;
  }
  state.sphereClickTimer += deltatime;


  // update mountains material
  scene.traverse((o) => {
    if(o.name == "mountain-group"){
      o.material.uniforms.uTime.value = time;
      // or just put 1 to keep it always active
      o.material.uniforms.uSphereActiveTimer.value = state.sphereActiveTimer;
    }
    if(o.name == "isle-group"){
      o.material.uniforms.uTime.value = time;
      // or just put 1 to keep it always active
      o.material.uniforms.uSphereActiveTimer.value = state.sphereActiveTimer;
    }
  });


  let fogTime = (time * 0.5) % 15;
  let windLevel = 0;
  if(fogTime > 4 && fogTime < 6) {
    let t = (fogTime - 4) / 2;
    windLevel = t;
    computeFogProgram.setWindLevel(t * deltatime, t);
    particles.setWindLevel(t * deltatime);
  } else if (fogTime >= 6 && fogTime <= 7) {
    windLevel = 1;
    computeFogProgram.setWindLevel(1 * deltatime, 1);
    particles.setWindLevel(1 * deltatime);
  }else if(fogTime > 7 && fogTime < 9) {
    let t = 1.0 - (fogTime - 7) / 2;
    windLevel = t;
    computeFogProgram.setWindLevel(t * deltatime, t);
    particles.setWindLevel(t * deltatime);
  }

  crystals.update(state.sphereActiveTimer);

  // blitProgram.blit(fluidSimFramebuffers.dye.write.texture, null);
  
  // renderer.setRenderTarget(mainFramebuffer);
  // renderer.render(scene, camera);

  whiteRocks.forEach((r, i) => {
    if(!r.transSpeed) r.transSpeed = Math.random() * 2.0 + 0.5;
    if(!r.transAmt) r.transAmt = Math.random() * 0.0004 + 0.0002;
    if(!r.rot) r.rot = new Vector3(0.003, 0.003, 0);
    // if(!r.rot) r.rot = new Vector3(Math.random() * 0.00001 + 0.000002, Math.random() * 0.00001 + 0.000002, 0);

    r.position.copy(
      new Vector3().copy(r.position).add(new Vector3(0, Math.sin(now * r.transSpeed) * r.transAmt, 0))
    );
    r.rotation.x += Math.sin(now) * r.rot.x;
    r.rotation.y += Math.sin(now) * r.rot.y;
  });

  // lightGrid.update();

  sphere.material.envMapIntensity = 1;
  sphere.material.roughness = 0.185;
  sphere.material.metalness = 0;
  if(state.sphereClickTimer < 1) {
    sphere.material.envMapIntensity = 0.3 + state.sphereClickTimer * 0.7;
    sphere.material.roughness = 0.25 * (1 - state.sphereClickTimer) + 0.185;
    // sphere.material.metalness = 0.95 * (1 - state.sphereClickTimer);
  }
  particles.update();
  scene.add(particles.mesh);
  // scene.add(lightGrid.mesh);
  renderer.setRenderTarget(mainFramebuffer);
  renderer.render(scene, camera);
  scene.remove(particles.mesh);


  // calc gbuffer
  positionProgram.scene = scene;
  scene.add(plane);
  scene.add(particles.mesh);
  positionProgram.blitPosition(positionRT);
  scene.remove(particles.mesh);
  scene.remove(plane);
  // scene.remove(lightGrid.mesh);


  // remember that meshes can only be in one scene at once
  planeScene.add(plane);
  positionProgram.scene = planeScene;
  positionProgram.blitPosition(planePositionRT);
  planeScene.remove(plane);



  // create reflected color buffer
  sphere.material.envMapIntensity = 0.35;
  reflectMaterials(scene);
  renderer.setRenderTarget(colorRT);
  renderer.render(scene, camera);
  renderer.setRenderTarget(null);
  reflectMaterials(scene);

  // using another buffer here is terrible and completely unnecessary
  // using another buffer here is terrible and completely unnecessary
  // using another buffer here is terrible and completely unnecessary
  // using another buffer here is terrible and completely unnecessary
  blitReflectedColorProgram.blitReflectedColor(colorRT.texture, planePositionRT.texture, positionRT.texture, reflectedColorRT);


  // create reflected position buffer
  reflectMaterials(scene);
  setReflectPositionPass(scene, true);
  renderer.setRenderTarget(reflectionPositionRT);
  renderer.render(scene, camera);
  renderer.setRenderTarget(null);
  setReflectPositionPass(scene, false);
  reflectMaterials(scene);


  reflectionDistanceProgram.blitReflectionDistance(
    reflectionPositionRT.texture, 
    planePositionRT.texture, 
    positionRT.texture, 
    reflectionDistanceRT);


  blurReflectionDistanceProgram.blur(reflectionDistanceRT.texture, reflectedColorRT.texture, blurDistanceRT);


  // fluid sim
  updateFluidSim(now);


  // calc water depth buffers
  depthProgram.scene = mountainsDepthScene;
  depthProgram.blitDepth(mountainsDepthRT);
  depthProgram.scene = waterScene;
  depthProgram.blitDepth(waterDepthRT);

  plane.material.uniforms.uMountainsDepth.value = mountainsDepthRT.texture;
  plane.material.uniforms.uWaterDepth.value = waterDepthRT.texture;
  plane.material.uniforms.uColor.value = blurDistanceRT.texture;
  plane.material.uniforms.uCameraPos.value = camera.position.clone();
  plane.material.uniforms.uBlurredReflectionDistance.value = blurDistanceRT.texture;
  // plane.material.uniforms.uFluidTexture.value = fluidSimFramebuffers.velocity.write.texture;
  plane.material.uniforms.uFluidTexture.value = fluidSimFramebuffers.dye.write.texture;

  // water pass
  renderer.autoClear = false;
  renderer.setRenderTarget(mainFramebuffer);
  renderer.render(waterScene, camera);
  renderer.autoClear = true;

  // renderer.setRenderTarget(null);
  // quad.material.uniforms.uSceneTex.value = mainFramebuffer.texture;
  // quad.material.uniforms.uFluidVelocityTex.value = fluidSimFramebuffers.velocity.write.texture;
  // quad.material.uniforms.uFluidDyeTex.value = fluidSimFramebuffers.dye.write.texture;
  // renderer.render(quadScene, quadCamera);

  // bundledTorusesScene.add(crystals.mesh);
  computeFogProgram.compute(mainFramebuffer.texture, postProcessBuffer);
  // bundledTorusesScene.remove(crystals.mesh);
  // scene.add(crystals.mesh);


  postProcessProgram.compute({ 
    windLevel, 
    time, 
    sphereActiveTimer: state.sphereActiveTimer 
  }, postProcessBuffer.texture, null);


  // blitProgram.blit(postProcessBuffer.texture, null);
  // blitProgram.blit(mainFramebuffer.texture, null);
  // blitProgram.blit(positionRT.texture, null);

  if(once("clock start")) {
    clock.start();
  }
}