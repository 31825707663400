import * as THREE from "three";

export default class BlitReflectedColor {
    constructor(renderer) {
        this.material = new THREE.ShaderMaterial({
            uniforms: {
                uPosition: { type: "t", value: null },
                uPlanePosition: { type: "t", value: null },
                uColor: { type: "t", value: null },
            },
            
            vertexShader: `
                varying vec2 vUv;

                void main() {
                    vUv = uv;
                    gl_Position = vec4(position.xy, 0.0, 1.0);    
                }
            `,

            fragmentShader: `
                uniform sampler2D uPosition;
                uniform sampler2D uPlanePosition;
                uniform sampler2D uColor;

                varying vec2 vUv;

                void main() {   
                    vec3 position = texture2D(uPosition, vUv).xyz;
                    vec3 planePosition = texture2D(uPlanePosition, vUv).xyz;
                    vec3 color = texture2D(uColor, vUv).xyz;

                    // this is necessary to only blit the reflections of the plane 
                    if(planePosition != position) color = vec3(0.0);

                    gl_FragColor = vec4(color, 1.0);
                }
            `,

            depthTest:  false,
            depthWrite: false,
        });

        this.mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(2,2), this.material);
        this.camera = new THREE.PerspectiveCamera( 45, 1 /* remember that the camera is worthless here */, 1, 1000 );
        this.renderer = renderer;

        this.scene = new THREE.Scene();
        this.scene.add(this.mesh);
    }

    blitReflectedColor(colorTexture, planePositionTexture, positionTexture, renderTargetDest) {
        this.renderer.setRenderTarget(renderTargetDest);

        this.material.uniforms.uPosition.value = positionTexture;
        this.material.uniforms.uPlanePosition.value = planePositionTexture;
        this.material.uniforms.uColor.value = colorTexture;
        this.renderer.render(this.scene, this.camera);

        this.renderer.setRenderTarget(null);
    }
}