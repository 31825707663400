import { useEffect, useRef } from "react";
import { createGlobalStyle } from "styled-components";
import { initThree } from "./graphics";
import { CgMouse, CgCamera } from 'react-icons/cg';

const GlobalStyles = createGlobalStyle`
  html, body, #root, .App {
    width:  100%;
    height: 100%;
    overflow: hidden;
    margin: 0;

    cursor: none;
  }


  .central-cursor {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    border: 2px solid white;

    position: absolute;
    top: 0; left: 0;

    box-sizing: border-box;

    pointer-events: none;

    transition: transform .1s, background .1s;

    &.active {
      /* opacity: 0; */
      transform: scale(0.65);
      background: white;
    }
  }

  .outer-cursor {
    width:  36px;
    height: 36px;
    border-radius: 100%;
    border: 2px solid white;

    position: absolute;
    top: 0; left: 0;

    box-sizing: border-box;

    opacity: 0.5;

    pointer-events: none;

    transition: transform .2s, opacity .2s;

    &::before {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      border: 1.25px solid white;
      content: " ";
      opacity: 0;
      position: absolute;
      top: calc(50% - 18px);
      left: calc(50% - 18px);
      box-sizing: border-box;

      transition: transform .2s, opacity .2s;
    }

    &.hover {
      transform: scale(1.4);
      opacity: 0.75;

      &::before {
        transform: scale(1.45);
        opacity: 0.35;
      }
    }

    /* &.active {
      &::before {
        transform: scale(1.55);
        opacity: 0;
      }
    } */

    pointer-events: none;
  }

  .mouse-icon {
    position: absolute;
    pointer-events: none;
    width: 1.5em;
    height: 1.5em;

    opacity: 0.5;

    transition: opacity .2s;
    animation: mouseanim 4s ease 0s infinite;

    &.hide {
      opacity: 0;
    }
  }

  .curtain {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    /* background: white; */
    background: rgb(64, 38, 25);
    pointer-events: none;


    p {
      position: absolute;
      bottom: 150px;
      left: 50%;
      transform: translate(-50%, 0);

      font-family: monospace, Helvetica, sans-serif;
      color: #ddd;
      text-transform: uppercase;
      letter-spacing: 0.65px;
      font-size: 11px;
      border-bottom: 1px solid white;

      transition: opacity .35s;

      &.hidden {
        opacity: 0;
      }

      opacity: 0.5;
    }
  }

  .camera-icon {
    width: 25px; 
    height: 20px;
  }

  .free-camera-btn {
    position: absolute;
    bottom: 20px;
    left: 20px;
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
    padding: 5px;
    border: 1px solid rgba(255,255,255, 0.5);
    box-sizing: border-box;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
  
    p {
      color: white;
      font-family: Helvetica, sans-serif;
      /* text-transform: uppercase; */
      font-size: 9px;
      letter-spacing: 0.5px;
      margin: 0 0 0 7px;
      text-align: center;
    }

    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  @keyframes mouseanim {
    0% {
      transform: translate(0, 0px);
    }
    80% {
      transform: translate(0, 0px);
    } 
    85% {
      transform: translate(0, 5px);
    } 
    90% {
      transform: translate(0, 0px);
    }
    95% {
      transform: translate(0, 5px);
    }
    100% {
      transform: translate(0, 0px);
    }
  }
`;

function App() {
  const canvasRef = useRef();

  useEffect(() => {
    initThree(canvasRef.current);
  }, []);

  return (
    <div className="App">
      <GlobalStyles/>
      <canvas ref={canvasRef} />      

      <div className="central-cursor"></div>
      <div className="outer-cursor"></div>
      <CgMouse color="white" className="mouse-icon"/>

      <div className="free-camera-btn">
        <CgCamera color="white" className="camera-icon"></CgCamera> <p>Free camera<br></br>OFF</p>
      </div>

      <div className="curtain">
        <p></p>
      </div>

    </div>
  );
}

export default App;
