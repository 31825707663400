import * as THREE from "three";
import { Vector3 } from "three";
import { Scene } from "three";
import { Raycaster, Vector2 } from "three";


// how to use it:

// import { updateFluidSim, initFluidSim, framebuffers }
// have fun!


let config = {
  SIM_RESOLUTION: 256,
  DYE_RESOLUTION: 1024,
  CAPTURE_RESOLUTION: 512,
  DENSITY_DISSIPATION: 0.6, // 1,
  VELOCITY_DISSIPATION: 0.2,
  PRESSURE: 1,
  PRESSURE_ITERATIONS: 20,
  CURL: 25,
  SPLAT_RADIUS: 0.25,
  SPLAT_FORCE: 6000,
  SHADING: false,
  COLORFUL: true,
  COLOR_UPDATE_SPEED: 10,
  PAUSED: false,
  BACK_COLOR: { r: 0, g: 0, b: 0 },
  TRANSPARENT: false,
};

function pointerPrototype() {
  this.id = -1;
  this.texcoordX = 0;
  this.texcoordY = 0;
  this.prevTexcoordX = 0;
  this.prevTexcoordY = 0;
  this.deltaX = 0;
  this.deltaY = 0;
  this.down = false;
  this.moved = false;
  // not restricted to [0, 255]
  this.color = [300, 200, 200];
}

let pointers = [];
let splatStack = [];
pointers.push(new pointerPrototype());

let canvas;
let scene;
let camera;
let renderer;
export let framebuffers = { 
  dye: null,
  velocity: null,
};

export function initFluidSim(rendererRef, canvasRef, overrideConfig) {
  if(overrideConfig) {
    config = overrideConfig;
  }

  canvas = canvasRef;
  renderer = rendererRef;

  scene = new THREE.Scene();

  camera = new THREE.PerspectiveCamera( 45, canvas.width / canvas.height, 0.1, 10 );

  initFramebuffers();
  initMaterials();
};

function debug() {
  multipleSplats(5);
  debugTexture(dye.read.texture);
}

function debugTexture(texture) {
  quadPlaneMesh.material = copyProgram;
  copyProgram.uniforms.uTexture.value = texture;
  renderer.setRenderTarget(null);
  renderer.render(scene, camera);
}

function getResolution(resolution) {
  let aspectRatio = canvas.width / canvas.height;
  if (aspectRatio < 1) aspectRatio = 1.0 / aspectRatio;

  let min = Math.round(resolution);
  let max = Math.round(resolution * aspectRatio);

  if (canvas.width > canvas.height) return { width: max, height: min };
  else return { width: min, height: max };
}

let dye;
let velocity;
let divergence;
let curl;
let pressure;
function initFramebuffers() {
  let simRes = getResolution(config.SIM_RESOLUTION);
  let dyeRes = getResolution(config.DYE_RESOLUTION);

  if (dye == null)
    dye = createDoubleFBO(dyeRes.width, dyeRes.height, THREE.LinearFilter);
  // dye = resizeDoubleFBO(dye, dyeRes.width, dyeRes.height, rgba.internalFormat, rgba.format, texType, filtering);
  else throw new Error("not implemented");

  if (velocity == null)
    velocity = createDoubleFBO(simRes.width, simRes.height, THREE.LinearFilter);
  // velocity = resizeDoubleFBO(velocity, simRes.width, simRes.height, rg.internalFormat, rg.format, texType, filtering);
  else throw new Error("not implemented");

  divergence = createFBO(simRes.width, simRes.height, THREE.NearestFilter);
  curl = createFBO(simRes.width, simRes.height, THREE.NearestFilter);
  pressure = createDoubleFBO(simRes.width, simRes.height, THREE.NearestFilter);

  framebuffers.dye = dye;
  framebuffers.velocity = velocity;
}

function createFBO(w, h, filtering) {
  let rt = new THREE.WebGLRenderTarget(w, h, {
    type: THREE.FloatType,
    minFilter: filtering,
    magFilter: filtering,
    format: THREE.RGBAFormat,
    depthBuffer: false,
    stencilBuffer: false,
    anisotropy: 1,
  });

  let texelSizeX = 1.0 / w;
  let texelSizeY = 1.0 / h;

  return {
    texture: rt.texture,
    fbo: rt,
    width: w,
    height: h,
    texelSizeX: texelSizeX,
    texelSizeY: texelSizeY,
  };
}
function createDoubleFBO(w, h, filtering) {
  let fbo1 = createFBO(w, h, filtering);
  let fbo2 = createFBO(w, h, filtering);

  return {
    width: w,
    height: h,
    texelSizeX: fbo1.texelSizeX,
    texelSizeY: fbo1.texelSizeY,

    get read() {
      return fbo1;
    },
    set read(value) {
      fbo1 = value;
    },
    get write() {
      return fbo2;
    },
    set write(value) {
      fbo2 = value;
    },

    swap() {
      let temp = fbo1;
      fbo1 = fbo2;
      fbo2 = temp;
    },
  };
}

let copyProgram;
let splatProgram;
let curlProgram;
let vorticityProgram;
let divergenceProgram;
let clearProgram;
let pressureProgram;
let gradienSubtractProgram;
let advectionProgram;
let displayMaterial;
let quadPlaneMesh;
function initMaterials() {
  copyProgram = new THREE.ShaderMaterial({
    uniforms: {
      uTexture: { type: "t", value: velocity.read.texture },
    },
    vertexShader: baseVertexShader,
    fragmentShader: copyShader,
  });

  splatProgram = new THREE.ShaderMaterial({
    uniforms: {
      uTarget: { type: "t", value: velocity.read.texture },
      aspectRatio: { value: canvas.width / canvas.height },
      point: { value: new THREE.Vector2(0, 0) },
      color: { value: new THREE.Vector3(0, 0, 0) },
      radius: { value: correctRadius(config.SPLAT_RADIUS / 100.0) },
    },
    vertexShader: baseVertexShader,
    fragmentShader: splatShader,
  });

  curlProgram = new THREE.ShaderMaterial({
    uniforms: {
      texelSize: {
        value: new THREE.Vector2(velocity.texelSizeX, velocity.texelSizeY),
      },
      uVelocity: { type: "t", value: velocity.read.texture },
    },
    vertexShader: baseVertexShader,
    fragmentShader: curlShader,
  });

  vorticityProgram = new THREE.ShaderMaterial({
    uniforms: {
      texelSize: {
        value: new THREE.Vector2(velocity.texelSizeX, velocity.texelSizeY),
      },
      uVelocity: { type: "t", value: velocity.read.texture },
      uCurl: { type: "t", value: curl.texture },
      curl: { value: config.CURL },
      dt: { value: 0.0 },
    },
    vertexShader: baseVertexShader,
    fragmentShader: vorticityShader,
  });

  divergenceProgram = new THREE.ShaderMaterial({
    uniforms: {
      texelSize: {
        value: new THREE.Vector2(velocity.texelSizeX, velocity.texelSizeY),
      },
      uVelocity: { type: "t", value: velocity.read.texture },
    },
    vertexShader: baseVertexShader,
    fragmentShader: divergenceShader,
  });

  clearProgram = new THREE.ShaderMaterial({
    uniforms: {
      uTexture: { type: "t", value: pressure.read.texture },
      value: { value: config.PRESSURE },
    },
    vertexShader: baseVertexShader,
    fragmentShader: clearShader,
  });

  pressureProgram = new THREE.ShaderMaterial({
    uniforms: {
      texelSize: {
        value: new THREE.Vector2(velocity.texelSizeX, velocity.texelSizeY),
      },
      uDivergence: { type: "t", value: divergence.texture },
      uPressure: { type: "t", value: pressure.read.texture },
    },
    vertexShader: baseVertexShader,
    fragmentShader: pressureShader,
  });

  gradienSubtractProgram = new THREE.ShaderMaterial({
    uniforms: {
      texelSize: {
        value: new THREE.Vector2(velocity.texelSizeX, velocity.texelSizeY),
      },
      uPressure: { type: "t", value: pressure.read.texture },
      uVelocity: { type: "t", value: velocity.read.texture },
    },
    vertexShader: baseVertexShader,
    fragmentShader: gradientSubtractShader,
  });

  advectionProgram = new THREE.ShaderMaterial({
    uniforms: {
      texelSize: {
        value: new THREE.Vector2(velocity.texelSizeX, velocity.texelSizeY),
      },
      uVelocity: { type: "t", value: velocity.read.texture },
      uSource: { type: "t", value: velocity.read.texture },
      dt: { value: 0.0 },
      dissipation: { value: config.VELOCITY_DISSIPATION },
    },
    vertexShader: baseVertexShader,
    fragmentShader: advectionShader,
  });

  displayMaterial = new THREE.ShaderMaterial({
    uniforms: {
      texelSize: {
        value: new THREE.Vector2(velocity.texelSizeX, velocity.texelSizeY),
      },
      uVelocity: { type: "t", value: velocity.read.texture },
      uTexture: { type: "t", value: dye.read.texture },
      uPressure: { type: "t", value: pressure.read.texture },
      uCurl: { type: "t", value: curl.texture },
    },
    vertexShader: baseVertexShader,
    fragmentShader: displayShaderSource,
  });

  let quadPlane = new THREE.PlaneBufferGeometry(2, 2);
  quadPlaneMesh = new THREE.Mesh(quadPlane, curlProgram);
  scene.add(quadPlaneMesh);
}

export function updateFluidSim() {
  const dt = calcDeltaTime();
  // if (resizeCanvas())
  //     initFramebuffers();

  updateColors(dt);
  applyInputs();
  // if (!config.PAUSED)
  step(dt);
  // render();
}

let lastUpdateTime = Date.now();
function calcDeltaTime() {
  let now = Date.now();
  let dt = (now - lastUpdateTime) / 1000;
  dt = Math.min(dt, 0.016666);
  lastUpdateTime = now;
  return dt;
}

function step(dt) {
  // we'll change quadPlaneMesh.material as needed
  quadPlaneMesh.material = curlProgram;
  curlProgram.uniforms.uVelocity.value = velocity.read.texture;
  renderer.setRenderTarget(curl.fbo);
  renderer.render(scene, camera);

  quadPlaneMesh.material = vorticityProgram;
  vorticityProgram.uniforms.uVelocity.value = velocity.read.texture;
  vorticityProgram.uniforms.uCurl.value = curl.texture;
  vorticityProgram.uniforms.curl.value = config.CURL;
  vorticityProgram.uniforms.dt.value = dt;
  renderer.setRenderTarget(velocity.write.fbo);
  renderer.render(scene, camera);
  velocity.swap();

  quadPlaneMesh.material = divergenceProgram;
  divergenceProgram.uniforms.uVelocity.value = velocity.read.texture;
  renderer.setRenderTarget(divergence.fbo);
  renderer.render(scene, camera);

  quadPlaneMesh.material = clearProgram;
  clearProgram.uniforms.uTexture.value = pressure.read.texture;
  clearProgram.uniforms.value.value = config.PRESSURE;
  renderer.setRenderTarget(pressure.write.fbo);
  renderer.render(scene, camera);

  quadPlaneMesh.material = pressureProgram;
  pressureProgram.uniforms.uDivergence.value = divergence.texture;
  for (let i = 0; i < config.PRESSURE_ITERATIONS; i++) {
    pressureProgram.uniforms.uPressure.value = pressure.read.texture;
    renderer.setRenderTarget(pressure.write.fbo);
    renderer.render(scene, camera);
    pressure.swap();
  }

  quadPlaneMesh.material = gradienSubtractProgram;
  gradienSubtractProgram.uniforms.uPressure.value = pressure.read.texture;
  gradienSubtractProgram.uniforms.uVelocity.value = velocity.read.texture;
  renderer.setRenderTarget(velocity.write.fbo);
  renderer.render(scene, camera);
  velocity.swap();

  quadPlaneMesh.material = advectionProgram;
  advectionProgram.uniforms.uVelocity.value = velocity.read.texture;
  advectionProgram.uniforms.uSource.value = velocity.read.texture;
  advectionProgram.uniforms.dt.value = dt;
  advectionProgram.uniforms.dissipation.value = config.VELOCITY_DISSIPATION;
  renderer.setRenderTarget(velocity.write.fbo);
  renderer.render(scene, camera);
  velocity.swap();

  quadPlaneMesh.material = advectionProgram;
  advectionProgram.uniforms.uVelocity.value = velocity.read.texture;
  advectionProgram.uniforms.uSource.value = dye.read.texture;
  advectionProgram.uniforms.dt.value = dt;
  advectionProgram.uniforms.dissipation.value = config.DENSITY_DISSIPATION;
  renderer.setRenderTarget(dye.write.fbo);
  renderer.render(scene, camera);
  dye.swap();

  renderer.setRenderTarget(null);
}
function render() {
  drawDisplay(window.innerWidth, window.innerHeight);
}
function drawDisplay(width, height) {
  renderer.setRenderTarget(null);
  quadPlaneMesh.material = displayMaterial;
  displayMaterial.uniforms.uTexture.value = dye.read.texture;
  displayMaterial.uniforms.uVelocity.value = velocity.read.texture;
  displayMaterial.uniforms.uPressure.value = pressure.read.texture;
  displayMaterial.uniforms.uCurl.value = curl.texture;
  renderer.render(scene, camera);
}
function splat(x, y, dx, dy, color, pointer, colorMultiplier) {
  if (!colorMultiplier) colorMultiplier = 1;

  quadPlaneMesh.material = splatProgram;
  splatProgram.uniforms.uTarget.value = velocity.read.texture;
  splatProgram.uniforms.aspectRatio.value = canvas.width / canvas.height;
  splatProgram.uniforms.point.value = new THREE.Vector2(x, y);
  splatProgram.uniforms.color.value = new THREE.Vector3(dx, dy, 0);
  splatProgram.uniforms.radius.value = correctRadius(
    config.SPLAT_RADIUS / 100.0
  );
  renderer.setRenderTarget(velocity.write.fbo);
  renderer.render(scene, camera);
  velocity.swap();

  // let colorIntensity = 0.2 * colorMultiplier;
  // let c = { r: 1, g: 0.8, b: 0.5 };
  // // let c = new THREE.Vector3(x,y,0).sub(new THREE.Vector3(dx, dy, 0)).normalize().multiplyScalar(0.5).addScalar(0.5);
  // let c = new THREE.Vector3(x,y,0).sub(new THREE.Vector3(dx, dy, 0)).normalize();
  // c = {r: c.x, g: c.y, b: c.z};
  // if (pointer.downRight) {
  //   c = { r: -1, g: -1, b: -1 };
  //   colorIntensity = 0.08;
  // }

  if (!pointer.downMiddle) {
    splatProgram.uniforms.uTarget.value = dye.read.texture;
    splatProgram.uniforms.color.value = new THREE.Vector3(1,1,1).multiplyScalar(0.2);
    // splatProgram.uniforms.color.value = new THREE.Vector3(
    //   /* color.r */ c.r,
    //   /* color.g */ c.g,
    //   /* color.b */ c.b
    // )
    // .normalize()
    // .multiplyScalar(colorIntensity);

    renderer.setRenderTarget(dye.write.fbo);
    renderer.render(scene, camera);
    dye.swap();
  }
}
function correctRadius(radius) {
  let aspectRatio = canvas.width / canvas.height;
  if (aspectRatio > 1) radius *= aspectRatio;
  return radius;
}

function applyInputs() {
  pointers.forEach((p) => {
    if (p.moved) {
      p.moved = false;
      splatPointer(p);
    }
  });
}
function multipleSplats(amount) {
  for (let i = 0; i < amount; i++) {
    let color = { r: 100, g: 100, b: 100 };

    const x = Math.random();
    const y = Math.random();
    const dx = 2700 * (Math.random() - 0.5);
    const dy = 2700 * (Math.random() - 0.5);
    splat(x, y, dx, dy, color, { downRight: Math.random() > 0.5 }, 10);
  }
}

const raycaster = new Raycaster();
export function initMouseCommands(camera, waterScene) {
  window.addEventListener("mousedown", (e) => {
    let posX = scaleByPixelRatio(e.clientX);
    let posY = scaleByPixelRatio(e.clientY);
    let pointer = pointers.find((p) => p.id == -1);
    if (pointer == null) pointer = new pointerPrototype();
    updatePointerDownData(pointer, -1, posX, posY, e.which == 3, e.which == 2);
  });

  window.addEventListener("touchstart", (e) => {
    let posX = scaleByPixelRatio(e.touches[0].clientX);
    let posY = scaleByPixelRatio(e.touches[0].clientY);
    let pointer = pointers.find((p) => p.id == -1);
    if (pointer == null) pointer = new pointerPrototype();
    updatePointerDownData(pointer, -1, posX, posY, e.which == 3, e.which == 2);
  });

  window.addEventListener("mousemove", (e) => {
    let pointer = pointers[0];
    // if (!pointer.down) return;
    // let posX = scaleByPixelRatio(e.clientX);
    // let posY = scaleByPixelRatio(e.clientY);

    // calculate mouse position in normalized device coordinates
	  // (-1 to +1) for both components
    let mouse = new Vector2(
      ( e.clientX / window.innerWidth ) * 2 - 1,
      - ( e.clientY / window.innerHeight ) * 2 + 1,
    );
    // update the picking ray with the camera and mouse position
    raycaster.setFromCamera( mouse, camera );
    // calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects( waterScene.children );
    if(intersects[0]) {
      let intersectionPoint = intersects[0].point;
      let distVec = intersectionPoint.clone().sub(new Vector3(0.71, -0.13, -0.05)).multiplyScalar(0.75);
      if(distVec.x > 1.0) distVec.x = 1.0;
      if(distVec.x < -1.0) distVec.x = -1.0;
      if(distVec.y > 1.0) distVec.y = 1.0;
      if(distVec.y < -1.0) distVec.y = -1.0;
      let fluidUv = distVec.clone().multiplyScalar(0.5).addScalar(0.5);

      let posX = fluidUv.x;
      let posY = fluidUv.z;

      updatePointerMoveData(pointer, posX, posY);
    }
  });

  window.addEventListener("touchmove", (e) => {
    let pointer = pointers[0];
    // if (!pointer.down) return;
    let posX = scaleByPixelRatio(e.touches[0].clientX);
    let posY = scaleByPixelRatio(e.touches[0].clientY);
    updatePointerMoveData(pointer, posX, posY);
  });

  window.addEventListener("mouseup", () => {
    updatePointerUpData(pointers[0]);
  });
}
function splatPointer(pointer) {
  let dx = pointer.deltaX * config.SPLAT_FORCE;
  let dy = pointer.deltaY * config.SPLAT_FORCE;

  splat(pointer.texcoordX, pointer.texcoordY, dx, dy, pointer.color, pointer);
}

function scaleByPixelRatio(input) {
  let pixelRatio = window.devicePixelRatio || 1;
  return Math.floor(input * pixelRatio);
}

function updatePointerDownData(pointer, id, posX, posY, rightKey, middleKey) {
  pointer.id = id;
  pointer.down = true;
  pointer.downRight = rightKey;
  pointer.downMiddle = middleKey;
  pointer.moved = false;
  pointer.texcoordX = posX / canvas.width;
  pointer.texcoordY = 1.0 - posY / canvas.height;
  pointer.prevTexcoordX = pointer.texcoordX;
  pointer.prevTexcoordY = pointer.texcoordY;
  pointer.deltaX = 0;
  pointer.deltaY = 0;
  pointer.color = generateColor(ciclingHue);
}

function updatePointerMoveData(pointer, posX, posY) {
  pointer.prevTexcoordX = pointer.texcoordX;
  pointer.prevTexcoordY = pointer.texcoordY;
  // pointer.texcoordX = posX / canvas.width;
  // pointer.texcoordY = 1.0 - posY / canvas.height;
  pointer.texcoordX = posX; 
  pointer.texcoordY = posY; 
  pointer.deltaX = correctDeltaX(pointer.texcoordX - pointer.prevTexcoordX);
  pointer.deltaY = correctDeltaY(pointer.texcoordY - pointer.prevTexcoordY);
  pointer.moved = Math.abs(pointer.deltaX) > 0 || Math.abs(pointer.deltaY) > 0;
}

function updatePointerUpData(pointer) {
  pointer.down = false;
}

let colorUpdateTimer = 0;
let ciclingHue = 0;
function updateColors(dt) {
  if (!config.COLORFUL) return;

  ciclingHue += dt * config.COLOR_UPDATE_SPEED * 0.03;
  ciclingHue = ciclingHue % 360;
  colorUpdateTimer += dt * config.COLOR_UPDATE_SPEED;
  // if (colorUpdateTimer >= 1) {
  colorUpdateTimer = wrap(colorUpdateTimer, 0, 1);
  pointers.forEach((p) => {
    p.color = generateColor(ciclingHue);
  });
  // }
}
function wrap(value, min, max) {
  let range = max - min;
  if (range == 0) return min;
  return ((value - min) % range) + min;
}

function correctDeltaX(delta) {
  let aspectRatio = canvas.width / canvas.height;
  if (aspectRatio < 1) delta *= aspectRatio;
  return delta;
}

function correctDeltaY(delta) {
  let aspectRatio = canvas.width / canvas.height;
  if (aspectRatio > 1) delta /= aspectRatio;
  return delta;
}

function generateColor(hue) {
  let c = HSVtoRGB(hue || Math.random(), 1.0, 1.0);
  c.r *= 0.15;
  c.g *= 0.15;
  c.b *= 0.15;
  return c;
}

function HSVtoRGB(h, s, v) {
  let r, g, b, i, f, p, q, t;
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0:
      r = v; g = t; b = p;
      break;
    case 1:
      r = q; g = v; b = p;
      break;
    case 2:
      r = p; g = v; b = t;
      break;
    case 3:
      r = p; g = q; b = v;
      break;
    case 4:
      r = t; g = p; b = v;
      break;
    case 5:
      r = v; g = p; b = q;
      break;
  }

  return {
    r,
    g,
    b,
  };
}

const baseVertexShader = `
    varying vec2 vUv;
    varying vec2 vL;
    varying vec2 vR;
    varying vec2 vT;
    varying vec2 vB;
    uniform vec2 texelSize;
    
    void main () {
        vUv = position.xy * 0.5 + 0.5;
        vL = vUv - vec2(texelSize.x, 0.0);
        vR = vUv + vec2(texelSize.x, 0.0);
        vT = vUv + vec2(0.0, texelSize.y);
        vB = vUv - vec2(0.0, texelSize.y);
        gl_Position = vec4(position.xy, 0.0, 1.0);
    }
    `;

const blurVertexShader = `
    varying vec2 vUv;
    varying vec2 vL;
    varying vec2 vR;
    uniform vec2 texelSize;
    
    void main () {
        vUv = position.xy * 0.5 + 0.5;
        float offset = 1.33333333;
        vL = vUv - texelSize * offset;
        vR = vUv + texelSize * offset;
        gl_Position = vec4(position.xy, 0.0, 1.0);
    }
    `;

const blurShader = `
    varying vec2 vUv;
    varying vec2 vL;
    varying vec2 vR;
    uniform sampler2D uTexture;
    
    void main () {
        vec4 sum = texture2D(uTexture, vUv) * 0.29411764;
        sum += texture2D(uTexture, vL) * 0.35294117;
        sum += texture2D(uTexture, vR) * 0.35294117;
        gl_FragColor = sum;
    }
    `;

const copyShader = `
    varying highp vec2 vUv;
    uniform sampler2D uTexture;
    
    void main () {
        gl_FragColor = texture2D(uTexture, vUv);
    }
    `;

const clearShader = `
    varying highp vec2 vUv;
    uniform sampler2D uTexture;
    uniform float value;
    
    void main () {
        gl_FragColor = value * texture2D(uTexture, vUv);
    }
    `;

const colorShader = `
    uniform vec4 color;
    
    void main () {
        gl_FragColor = color;
    }
    `;

const checkerboardShader = `
    varying vec2 vUv;
    uniform sampler2D uTexture;
    uniform float aspectRatio;
    
    #define SCALE 25.0
    
    void main () {
        vec2 uv = floor(vUv * SCALE * vec2(aspectRatio, 1.0));
        float v = mod(uv.x + uv.y, 2.0);
        v = v * 0.1 + 0.8;
        gl_FragColor = vec4(vec3(v), 1.0);
    }
    `;

const displayShaderSource = `
    varying vec2 vUv;
    varying vec2 vL;
    varying vec2 vR;
    varying vec2 vT;
    varying vec2 vB;
    uniform sampler2D uTexture;
    uniform sampler2D uBloom;
    uniform sampler2D uSunrays;
    uniform sampler2D uDithering;
    uniform sampler2D uVelocity;
    uniform sampler2D uPressure;
    uniform sampler2D uCurl;
    uniform vec2 ditherScale;
    uniform vec2 texelSize;
    
    vec3 linearToGamma (vec3 color) {
        color = max(color, vec3(0));
        return max(1.055 * pow(color, vec3(0.416666667)) - 0.055, vec3(0));
    }
    
    vec3 acesFilm(const vec3 x) {
        const float a = 2.51;
        const float b = 0.03;
        const float c = 2.43;
        const float d = 0.59;
        const float e = 0.14;
        return clamp((x * (a * x + b)) / (x * (c * x + d ) + e), 0.0, 1.0);
    }
    
    
    void main () {
        vec3 c = texture2D(uTexture, vUv).rgb;
    
    
        float a = max(c.r, max(c.g, c.b));
        gl_FragColor = vec4(c, a);
        // return;  // uncomment to see the base version of the shader
    
    
        vec3 vdata = ((texture2D(uVelocity, vUv).rgb)   * 0.5 + 0.5)  * 0.007;
        vec3 pdata = vec3(0.0, 0.0, texture2D(uPressure, vUv).r * 0.01);
        float cdata = texture2D(uCurl, vUv).r * 0.01;
        vec3 finalVelocityColor = vec3(0.6) + vdata * (1.0 + cdata);
        gl_FragColor = vec4(finalVelocityColor, 1.0);
        // return;  // uncomment to see the velocity version of the shader
    
    
        // // mixed version of the shader
        // gl_FragColor = vec4(c, a) * gl_FragColor * 2.0 + gl_FragColor * 0.25;
        
    
        float x = 0.6 + vdata.x;
        float y = 0.6 + vdata.y;
        float z = 0.6 + vdata.z;
        if(x < 0.0) x = 0.0;
        if(y < 0.0) y = 0.0;
        if(z < 0.0) z = 0.0;
        vec3 vcol = vec3(x,y,z);
        vec3 dcol = vec4(c, a).rgb;
        vec3 fcol = dcol + vcol * 0.25;
        if(fcol.r < 0.0) fcol.r = 0.0;
        if(fcol.g < 0.0) fcol.g = 0.0;
        if(fcol.b < 0.0) fcol.b = 0.0;
    
        gl_FragColor.rgb = acesFilm(fcol);
    }
    `;

const splatShader = `
    varying vec2 vUv;
    uniform sampler2D uTarget;
    uniform float aspectRatio;
    uniform vec3 color;
    uniform vec2 point;
    uniform float radius;
    
    void main () {
        vec2 p = vUv - point.xy;
        p.x *= aspectRatio;
        vec3 splat = exp(-dot(p, p) / radius) * color;
        vec3 base = texture2D(uTarget, vUv).xyz;
        gl_FragColor = vec4(base + splat, 1.0);
    }
    `;

const advectionShader = `
    varying vec2 vUv;
    uniform sampler2D uVelocity;
    uniform sampler2D uSource;
    uniform vec2 texelSize;
    uniform vec2 dyeTexelSize;
    uniform float dt;
    uniform float dissipation;
    
    void main () {
        vec2 coord = vUv - dt * texture2D(uVelocity, vUv).xy * texelSize;
        vec4 result = texture2D(uSource, coord);
    
        float decay = 1.0 + dissipation * dt;
        gl_FragColor = result / decay;
    }`;

const divergenceShader = `
    varying highp vec2 vUv;
    varying highp vec2 vL;
    varying highp vec2 vR;
    varying highp vec2 vT;
    varying highp vec2 vB;
    uniform sampler2D uVelocity;
    
    void main () {
        float L = texture2D(uVelocity, vL).x;
        float R = texture2D(uVelocity, vR).x;
        float T = texture2D(uVelocity, vT).y;
        float B = texture2D(uVelocity, vB).y;
    
        vec2 C = texture2D(uVelocity, vUv).xy;
        if (vL.x < 0.0) { L = -C.x; }
        if (vR.x > 1.0) { R = -C.x; }
        if (vT.y > 1.0) { T = -C.y; }
        if (vB.y < 0.0) { B = -C.y; }
    
        float div = 0.5 * (R - L + T - B);
        gl_FragColor = vec4(div, 0.0, 0.0, 1.0);
    }
    `;

const curlShader = `
    varying highp vec2 vUv;
    varying highp vec2 vL;
    varying highp vec2 vR;
    varying highp vec2 vT;
    varying highp vec2 vB;
    uniform sampler2D uVelocity;
    
    void main () {
        float L = texture2D(uVelocity, vL).y;
        float R = texture2D(uVelocity, vR).y;
        float T = texture2D(uVelocity, vT).x;
        float B = texture2D(uVelocity, vB).x;
        float vorticity = R - L - T + B;
        gl_FragColor = vec4(0.5 * vorticity, 0.0, 0.0, 1.0);
    }
    `;

const vorticityShader = `
    varying vec2 vUv;
    varying vec2 vL;
    varying vec2 vR;
    varying vec2 vT;
    varying vec2 vB;
    uniform sampler2D uVelocity;
    uniform sampler2D uCurl;
    uniform float curl;
    uniform float dt;
    
    void main () {
        float L = texture2D(uCurl, vL).x;
        float R = texture2D(uCurl, vR).x;
        float T = texture2D(uCurl, vT).x;
        float B = texture2D(uCurl, vB).x;
        float C = texture2D(uCurl, vUv).x;
    
        vec2 force = 0.5 * vec2(abs(T) - abs(B), abs(R) - abs(L));
        force /= length(force) + 0.0001;
        force *= curl * C;
        force.y *= -1.0;
    
        vec2 vel = texture2D(uVelocity, vUv).xy;
        gl_FragColor = vec4(vel + force * dt, 0.0, 1.0);
    }
    `;

const pressureShader = `
    varying highp vec2 vUv;
    varying highp vec2 vL;
    varying highp vec2 vR;
    varying highp vec2 vT;
    varying highp vec2 vB;
    uniform sampler2D uPressure;
    uniform sampler2D uDivergence;
    
    void main () {
        float L = texture2D(uPressure, vL).x;
        float R = texture2D(uPressure, vR).x;
        float T = texture2D(uPressure, vT).x;
        float B = texture2D(uPressure, vB).x;
        float C = texture2D(uPressure, vUv).x;
        float divergence = texture2D(uDivergence, vUv).x;
        float pressure = (L + R + B + T - divergence) * 0.25;
        gl_FragColor = vec4(pressure, 0.0, 0.0, 1.0);
    }
    `;

const gradientSubtractShader = `
    varying highp vec2 vUv;
    varying highp vec2 vL;
    varying highp vec2 vR;
    varying highp vec2 vT;
    varying highp vec2 vB;
    uniform sampler2D uPressure;
    uniform sampler2D uVelocity;
    
    void main () {
        float L = texture2D(uPressure, vL).x;
        float R = texture2D(uPressure, vR).x;
        float T = texture2D(uPressure, vT).x;
        float B = texture2D(uPressure, vB).x;
        vec2 velocity = texture2D(uVelocity, vUv).xy;
        velocity.xy -= vec2(R - L, T - B);
        gl_FragColor = vec4(velocity, 0.0, 1.0);
    }
    `;
