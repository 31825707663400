import { AddEquation, AdditiveBlending, BufferAttribute, CustomBlending, DoubleSide, Euler, OneFactor, ShaderMaterial } from "three";
import { Mesh } from "three";
import { Raycaster } from "three";
import { Vector2 } from "three";
import { Clock } from "three";
import { BufferGeometry } from "three";
import { Spherical } from "three";
import { Vector3 } from "three";

export default class CentralParticles {
  constructor(camera, waterScene) {
    
    let n = 800;
    this.hoverN = 200;
    this.particles = [];
    for(let i = 0; i < n; i++) {
      this.particles.push(this.createParticle());
    }

    this.particlesGeo = new BufferGeometry();
    const vertices = new Float32Array(n * 9);
    this.particlesGeo.setAttribute( 'position', new BufferAttribute( vertices, 3 ) );


    this.particlesMaterial = new ShaderMaterial({
      vertexShader: `
      varying vec3 vWorldPos;

      void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);    
        vWorldPos = (modelMatrix * vec4(position, 1.0)).xyz;
      }
      `,

      fragmentShader: `
      varying vec3 vWorldPos;
     
      void main() {   

        // vec3 sphereCenter = vec3(0.71, -0.13, -0.05);
        // vec3 dir = normalize(vWorldPos - sphereCenter);

        // float d = clamp(dot(dir, vec3(1.0, -0.75, -0.8)), 0.0, 1.0);
        // d = pow(d, 6.0);
        // d = clamp(d, 0.0, 0.75);

        gl_FragColor = vec4(1.0, 0.5, 0.5, 0.8);
        // gl_FragColor = vec4(vec3(1.0, 0.5, 0.5) * (1.0 - d), 0.8);
      }
      `,

      side: DoubleSide,
      transparent: true,
      blendEquation: AddEquation,
      blending: AdditiveBlending,
    });

    this.mesh = new Mesh(this.particlesGeo, this.particlesMaterial);
    this.clock = new Clock();
    this.clock.start();

    const raycaster = new Raycaster();
    this.raycastPoint = new Vector3(0,0,0);
    this.raycastDelta = new Vector3(0,0,0);
    this.windLevel = 0;

    window.addEventListener("mousemove", (e) => {
      let mouse = new Vector2(
        ( e.clientX / window.innerWidth ) * 2 - 1,
        - ( e.clientY / window.innerHeight ) * 2 + 1,
      );
      raycaster.setFromCamera( mouse, camera );
      const intersects = raycaster.intersectObjects( waterScene.children );
      if(intersects[0]) {
        let intersectionPoint = intersects[0].point;

        if(this.raycastPoint.x == 0 && this.raycastPoint.y == 0 && this.raycastPoint.z == 0) {
          this.raycastPoint = intersectionPoint;
        } else {
          this.raycastDelta = this.raycastPoint.clone().sub(intersectionPoint);
          this.raycastPoint = intersectionPoint;

          for(let i = 0; i < 2; i++) {
            let pi = Math.floor(Math.random() * this.hoverN) + (n - this.hoverN);
            if(pi >= n) pi = n - 1;
            if(pi <= 0) pi = 0;

            let sphericalDir = new Spherical(
              0.025 + Math.random() * 0.1, 
              Math.random() * Math.PI, 
              Math.random() * 2 * Math.PI);

            this.particles[pi] = this.createParticle();
            this.particles[pi].position = this.raycastPoint.clone().add(
              new Vector3().setFromSpherical(sphericalDir).add(new Vector3(0, 0.05, 0))
            );
            this.particles[pi].velocity = this.raycastDelta.clone().multiplyScalar(-(Math.random() * 1.0 + 0.5) * 0.5);
          }
        }
      }
    });
  }

  createParticle() {
    let sphericalDir = new Spherical(
      // Math.random() * 1 + 0.07, 
      0.35 + Math.pow(Math.random() * 1, 1), 
      Math.random() * Math.PI, 
      Math.random() * 2 * Math.PI);

    return {
      position: new Vector3()
                .setFromSpherical(sphericalDir)
                .add(new Vector3(0.71, -0.13, -0.05)),
      velocity: new Vector3(0,0,0),
      rotx: 0,
      roty: 0,
      rotz: 0,
      rotxS: Math.random() * 0.02,
      rotyS: Math.random() * 0.02,
      rotzS: Math.random() * 0.02,
      t: 0,
      // radius: 0.01 + Math.random() * 0.025,
      radius: 0.013 + Math.random() * 0.035,

      accumulatedWindTranslation: 0,
      age: Math.random() * 20,

      swirlRadius: Math.random() * 0.001,
      swirlSpeed: Math.random() * 0.7 + 0.3,
      gravitySpeed: Math.random() * 0.01 + Math.random() * 0.002,
    };
  }

  setWindLevel(value) {
    this.windLevel = value;
  }

  update() {
    let delta = this.clock.getDelta();

    let n = this.particles.length;

    for(let i = 0; i < n; i++) {
      // reset particle
      if(
        (
          this.particles[i].position.y < -0.5 || 
          this.particles[i].age > 20
          // this.particles[i].accumulatedWindTranslation > 0.02 
        ) && 
        i <= (n - this.hoverN) /* i > this.hoverN will be treated differently */
      ) {
        let sphericalDir = new Spherical(
          // Math.random() * 1 + 0.07, 
          0.35 + Math.pow(Math.random() * 1, 1), 
          Math.random() * Math.PI, 
          Math.random() * 2 * Math.PI);
  
        this.particles[i] = this.createParticle();
      }

      let particle = this.particles[i];
      let pos = particle.position;
      particle.rotx += particle.rotxS;
      particle.roty += particle.rotyS;
      particle.rotz += particle.rotzS;

      particle.age += delta;

      particle.t += delta;
      particle.position.add(
        new Vector3(
          Math.sin(particle.t * particle.swirlSpeed) * particle.swirlRadius, 
          -particle.gravitySpeed * delta, 
          Math.cos(particle.t * particle.swirlSpeed) * particle.swirlRadius
        )
      );

      // add wind
      if(this.windLevel > 0) {
        let windTranslation = this.windLevel * 0.15;
        // particle.accumulatedWindTranslation += windTranslation;
        particle.position.add(new Vector3(windTranslation, 0, 0));
      }

      // perturb velocity
      particle.position.add(particle.velocity);
      particle.velocity.multiplyScalar(0.985);

      let vIdx = i * 9;
      let v0 = new Vector3(-0.1, 0.0, 0).multiplyScalar(particle.radius);
      let v1 = new Vector3(+0.1, 0.0, 0).multiplyScalar(particle.radius);
      let v2 = new Vector3(0.1, 0.15, 0).multiplyScalar(particle.radius);

      v0.applyEuler(new Euler(particle.rotx, particle.roty, particle.rotz));
      v1.applyEuler(new Euler(particle.rotx, particle.roty, particle.rotz));
      v2.applyEuler(new Euler(particle.rotx, particle.roty, particle.rotz));

      this.particlesGeo.attributes.position.array[vIdx + 0] = v0.x + pos.x;
      this.particlesGeo.attributes.position.array[vIdx + 1] = v0.y + pos.y;
      this.particlesGeo.attributes.position.array[vIdx + 2] = v0.z + pos.z;
      
      this.particlesGeo.attributes.position.array[vIdx + 3] = v1.x + pos.x;
      this.particlesGeo.attributes.position.array[vIdx + 4] = v1.y + pos.y;
      this.particlesGeo.attributes.position.array[vIdx + 5] = v1.z + pos.z;
      
      this.particlesGeo.attributes.position.array[vIdx + 6] = v2.x + pos.x;
      this.particlesGeo.attributes.position.array[vIdx + 7] = v2.y + pos.y;
      this.particlesGeo.attributes.position.array[vIdx + 8] = v2.z + pos.z;
    }
  
    this.particlesGeo.attributes.position.needsUpdate = true;
  }
  
}