import { MeshBasicMaterial } from "three";
import { DoubleSide, ShaderMaterial } from "three";

export const mountainsMaterial = (texture) => {
  return new ShaderMaterial({
    side: DoubleSide,
    
    uniforms: {
      map: { type: "t", value: texture },
      uTime: { value: 0 },
      uSphereActiveTimer: { value: 0 },
    },

    vertexShader: `
      varying vec3 vFragPos;
      varying vec2 vUv;

      void main() {


        // ******* this code block is necessary for compatibility with
        // reflectable-material ********
        vec4 mvPosition = vec4(position, 1.0);
        mvPosition = modelViewMatrix * mvPosition;
        // ******* this code block is necessary for compatibility with
        // reflectable-material ********


        gl_Position = projectionMatrix * mvPosition;
        vFragPos = (modelMatrix * vec4(position, 1.0)).xyz;

        vUv = uv;
      }
    `,

    fragmentShader: `
      varying vec3 vFragPos;
      varying vec2 vUv;
   
      uniform sampler2D map;
      uniform float uTime;
      uniform float uSphereActiveTimer;

      vec2 GetGradient(vec2 intPos, float t) {
        // Uncomment for calculated rand
        float rand = fract(sin(dot(intPos, vec2(12.9898, 78.233))) * 43758.5453);;
        
        // Texture-based rand (a bit faster on my GPU)
        // float rand = texture(iChannel0, intPos / 64.0).r;
        
        // Rotate gradient: random starting rotation, random rotation rate
        float angle = 6.283185 * rand + 4.0 * t * rand;
        return vec2(cos(angle), sin(angle));
      }
    
      float Pseudo3dNoise(vec3 pos) {
        vec2 i = floor(pos.xy);
        vec2 f = pos.xy - i;
        vec2 blend = f * f * (3.0 - 2.0 * f);
        float noiseVal = 
            mix(
                mix(
                    dot(GetGradient(i + vec2(0, 0), pos.z), f - vec2(0, 0)),
                    dot(GetGradient(i + vec2(1, 0), pos.z), f - vec2(1, 0)),
                    blend.x),
                mix(
                    dot(GetGradient(i + vec2(0, 1), pos.z), f - vec2(0, 1)),
                    dot(GetGradient(i + vec2(1, 1), pos.z), f - vec2(1, 1)),
                    blend.x),
            blend.y
        );
        return noiseVal / 0.7; // normalize to about [-1..1]
      }
    

      void main() {
        // this is only necessary for compatibility with reflectable-material
        // you can assume that this line doesn't exist at all
        gl_FragColor = linearToOutputTexel( gl_FragColor );

        float noiseVisibility = clamp(uSphereActiveTimer - 0.5, 0.0, 1.0);

        vec3 color = texture2D(map, vUv).xyz;

        vec2 nuv = vec2(vFragPos.x, vFragPos.z);

        float offset = uTime * 0.01;
        
        float noise = (vFragPos.x + 99.0) * 10.0 + 
          (Pseudo3dNoise(vec3(nuv, offset * 6.0) * 0.5) * 0.5 + 0.5) * 30.0 + 
          (Pseudo3dNoise(vec3(nuv, offset * 0.5) * 4.0) * 0.5 + 0.5) * 6.0 +
          (Pseudo3dNoise(vec3(nuv, offset * 0.5) * 20.0) * 0.5 + 0.5) * 1.1 +  
          (Pseudo3dNoise(vec3(nuv, offset * 0.5) * 40.0) * 0.5 + 0.5) * 0.5 
        ;
        float modnoise = mod(noise, 3.0);
        float opwindow = 0.09;
        float linewidth = 0.3;

        if(modnoise > linewidth) {
          noise = 0.0;
        } else if (modnoise > linewidth - opwindow) {
          noise = 1.0 - (modnoise - (linewidth-opwindow)) / opwindow;
        } else if (modnoise < opwindow) {
          noise = modnoise / opwindow;
        } else {
          noise = 1.0;
        }
        noise *= 1.65 * noiseVisibility;


        // time-based noise animation
        float timeMult = 1.0;
        float animT = mod(uTime * timeMult, 30.0);

        if(animT > 3.5 && animT < 5.0) {
          float t = (animT - 3.5) / 1.5;

          float targetDist = (t - 0.15) * 9.0;
          float dist = length(vec3(vFragPos.x, 0.0, vFragPos.z) - vec3(0.71, 0.0, -0.05));
          
          dist += Pseudo3dNoise(vec3(nuv, uTime * 0.2) * 4.7) * 0.35;
          dist = abs(dist - targetDist);

          dist = 1.0 - clamp(dist, 0.0, 0.9) / 0.9;
        
          noise *= dist;
          noise *= 2.0;
          color *= 1.0 + noise;

        } else if(animT > 5.5 && animT < 6.5) {
          float t = ((animT - 5.5) / 1.0) * 3.0 - 1.5;
          t += Pseudo3dNoise(vec3(nuv, uTime * 0.2) * 4.7) * 1.0;
          t = clamp(t, 0.0, 1.0);
          noise *= t;
          color *= 1.0 + noise;
        } else if(animT > 6.5 && animT < 11.0) {

          color *= 1.0 + noise;

        } else if(animT > 11.0 && animT < 12.0) {
          float t = -((animT - 11.0) / 1.0) * 3.0 + 1.5;
          t += Pseudo3dNoise(vec3(nuv, uTime * 0.2) * 4.7) * 1.0;
          t = clamp(t, 0.0, 1.0);
          noise *= t;
          color *= 1.0 + noise;
        
        } else if(animT < 16.0) {
          // pause          
        } else if(animT > 16.0 && animT < 24.0) {
          float t = ((animT - 16.0) / 2.0) * 3.0 - 1.5;
          t += Pseudo3dNoise(vec3(nuv, uTime * 0.2) * 2.0) * 0.8;
          t += Pseudo3dNoise(vec3(nuv, uTime * 0.2) * 0.65) * 0.2;
          t = clamp(t, 0.0, 1.0) * noiseVisibility;

          color = mix(color, color * noise * 0.5 + color * 0.07, t);
        } else if (animT > 24.0 && animT < 26.0) {
          float t = -((animT - 24.0) / 2.0) * 3.0 + 1.5;
          t += Pseudo3dNoise(vec3(nuv, uTime * 0.2) * 2.0) * 0.8;
          t += Pseudo3dNoise(vec3(nuv, uTime * 0.2) * 0.65) * 0.2;
          t = clamp(t, 0.0, 1.0) * noiseVisibility;

          color = mix(color, color * noise * 0.5 + color * 0.07, t);
        }

        gl_FragColor = vec4(color, 1.0);
      }
    `,
  });
};















/**

backup

 // time-based noise animation
        float timeMult = 1.0;
        float animT = mod(uTime * timeMult, 15.0);
        bool darkmode = false;
        
        if(mod(uTime * timeMult, 45.0) < 15.0) {
          // darkmode = true;
        }

        if(animT > 3.5 && animT < 5.0) {
          float t = (animT - 3.5) / 1.5;

          float targetDist = (t - 0.15) * 9.0;
          float dist = length(vec3(vFragPos.x, 0.0, vFragPos.z) - vec3(0.71, 0.0, -0.05));
          dist = abs(dist - targetDist);

          dist = 1.0 - clamp(dist, 0.0, 0.9) / 0.9;
        
          noise *= dist;
          noise *= 2.0;
        } else if(animT > 5.5 && animT < 6.5) {
          float t = ((animT - 5.5) / 1.0) * 3.0 - 1.5;
          t += Pseudo3dNoise(vec3(nuv, uTime * 0.2) * 4.7) * 1.0;
          t = clamp(t, 0.0, 1.0);
          noise *= t;
          if(darkmode) {
            color *= (1.0 - t) * 0.83 + 0.17;
            noise *= 2.5;
          }
        } else if(animT > 6.5 && animT < 11.0) {
          if(darkmode) {
            color *= 0.17;
            noise *= 2.5;
          } 
        } else if(animT > 11.0 && animT < 12.0) {
          float t = -((animT - 11.0) / 1.0) * 3.0 + 1.5;
          t += Pseudo3dNoise(vec3(nuv, uTime * 0.2) * 4.7) * 1.0;
          t = clamp(t, 0.0, 1.0);
          noise *= t;
          
          if(darkmode) {
            color *= (1.0 - t) * 0.83 + 0.17;
            noise *= 2.5;
          }
        } else {
          noise *= 0.0;
        }



 */